@import "../../../assets/scss/variables";

.purchases-page {
    .page-heading {
        margin-top: 0;
    }
    .table-grid {
        .MuiDataGrid-cell {
            cursor: pointer;
        }
    }
    .clicks-content-wrap {
        padding-top: 45px;
    }
}
