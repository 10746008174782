@import '../../../assets/scss/variables';

.messages-page {
    .item-container {
        background-color: $white;
        border: 1px solid $border-gray;
    }
    .section-heading {
        margin: 30px 0;
    }

    .no-items {
        margin: 13px 16px;
    }
    .item-message-container {

        p {
            margin: 5px 0;
        }
        .view-button {
            font-size: 14px !important;
        }
    }
}
