@import "../../../../assets/scss/variables";

.mobile-header {
    width: 100%;
    height: 56px;
    background: $white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    border-bottom: 1px solid $border-gray;
    .grid-container {
        height: 100%;
        width: 100%;
        .logo-icon {
            img {
                width: 24px;
                height: 24px;
                border-radius: 50%
            };
            &.filter {
                img {
                    filter: $filter-light-blue;
                }
            }
        }
        .community-name {
            font-size: 16px
        }
        .action-icon {
            font-size: 24px;
            filter: $filter-light-blue;
        }
    };
}