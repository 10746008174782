@import "../../../assets/scss/variables";

.MuiAppBar-colorDefault {
    background-color: white!important;
}

.table-grid {
    margin-bottom: 50px;
    .banner-action {
        cursor: pointer;
        &.margin {
            margin-top: 10px;
        }
        &:hover {
            filter: $filter-light-blue;
            transform: scale(1.1);
        }
    }
}

.articles-page {
    .no-articles {
        text-align: center;
    }
}

.confirm-edit-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-preview {
    &.list-preview {
        .preview-container {
            .phone-container {
                .content-container {
                    flex-direction: column;
                    .scroll-container {
                        width: 100%;
                        height: 100%;
                        overflow-x: auto;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                    
                        /* Hide scrollbar for IE, Edge and Firefox */
                        & {
                            -ms-overflow-style: none; /* IE and Edge */
                            scrollbar-width: none; /* Firefox */
                        }
                        & > * {
                            margin-top: 1px;
                        }
                    }
                }
            }
        }
    }
}
