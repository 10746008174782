@import "../../../assets/scss/variables";

.surveys-modal-page-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 35%;
    height: 100%;
    z-index: -1;
    &.open {
        left: 0;
        z-index: 10;
    }
    .surveys-modal-page {
        position: absolute;
        width: 100%;
        height: 100%;
        left: -100%;
        background: $white;
        border-left: 1px solid $border-gray;
        border-right: 1px solid $border-gray;
        transition: all 0.5s ease-out;
        &.open {
            left: 0;
        }
        .survey-modal-header {
            height: 60px;
            border-bottom: 1px solid $border-gray;
            padding: 0 24px;
            h3 {
                color: rgba($black, 0.87);
            }
            .export-button {
                cursor: pointer;
                margin: 0 15px;
                &-text {
                    margin: 0 5px;
                    color: $light-blue;
                }
                .icon {
                    width: 1.42rem;
                    height: 1.42rem;
                }
            }
            .close-container {
                top: 20px;
                left: 20px;
                .icon-close {
                    cursor: pointer;
                    font-size: 1.5rem;
                }
            }
        }
        .survey-modal-footer {
            margin: 0 24px;
            .content-wrap {
                margin: 45px 0;
                overflow-y: auto;
                .question-container {
                    min-height: 250px;
                    background-color: $light-gray;
                    border-radius: 5px;
                    margin-bottom: 16px;
                    padding: 10px 16px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .question-header {
                        margin-bottom: 5px;
                    }
                    .question-number {
                        color: $dark-gray;
                        font-size: 0.875rem;
                    }
                    .question-label {
                        margin-bottom: 24px;
                    }
                    .question-results {
                        .question-answers-container {
                            .colored-bullet {
                                width: 14px;
                                height: 14px;
                                border-radius: 3px;
                                margin: 1px 0;
                                box-shadow: 0 0px 25px 1px rgba(0, 0, 0, 0.2);
                            }
                            .answer-container {
                                margin-bottom: 12px;
                                .answer {
                                    margin: 0 13px;
                                    .answer-percent {
                                        margin-top: 3px;
                                        color: $dark-gray;
                                        font-size: 0.75rem;
                                    }
                                }
                            }
                        }
                        .pie-chart {
                            box-shadow: 0 0px 25px 1px rgba(0, 0, 0, 0.2);
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
        .total-count {
            color: $dark-gray;
        }
    }
}

.modal-preview.participants-modal {
    .question-container {
        position: relative;
        width: 566px;
        height: 570px;
        background: $white;
        margin: 10% auto 0;
        padding: 20px 23px;
        overflow-y: auto;
        @media only screen and (max-width: 1024px) {
            width: 424.5px;
            height: 427.5px;
        }
        @media only screen and (max-width: 768px) {
            width: 283px;
            height: 285px;
        }
        .question-header-container {
            margin-bottom: 28px;
            .close-container {
                .icon-close {
                    cursor: pointer;
                    font-size: 1.5rem;
                }
            }
        }
        .question-label-container {
            background-color: $light-gray;
            padding: 16px;
            border-radius: 3px;
            .question-number {
                margin-bottom: 5px;
                color: $dark-gray;
                font-size: 0.875rem;
            }
        }
        .answer-container {
            margin-top: 27px;
            .answer {
                margin-bottom: 12px;
                & > * {
                    margin: 0 2px;
                }
                &-label {
                    font-weight: bold;
                }
                &-percent {
                    color: $dark-gray;
                    font-size: 0.75rem;
                }
            }
            .user-container {
                padding: 7.5px 0;
                border-bottom: 1px solid $border-gray;
                .date {
                    color: $dark-gray;
                    font-size: 0.75rem;
                }
            }
        }
    }
}
