@import "../../assets/scss/variables";

.select-language-container {
    width: 100px;
    &.no-filter {
        filter: unset;
    }
    .select-language {
        width: 50px;
        margin: 0;
        div:focus {
            background-color: unset;
        }
        &.MuiInput-underline {
            &::before, &::after {
                display: none;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        width: 45px;
        .select-language {
            font-size: 0.8rem;
        }
    }
}