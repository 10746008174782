@import "../../../assets/scss/variables";

.user-edit {
  .scroll-wrap {
    overflow-x: auto;
    .edit-row-container {
      padding: 9px 0;
    }
  }
  .icon-close {
    cursor: pointer;
  }
}

.users-page {
  .total-box {
    display: flex;
    align-items: center;
    gap: 15px;
    @media only screen and (max-width: 1024px) {
      gap: 5px;
    }
  }

  .select-preview-filter {
    margin-right: 20px;
  }

  .delete-user {
    margin-top: 35px;
  }

  .content-wrap {
    padding-top: 10px;
    padding-bottom: 40px;
  }

  .selected-items-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .link-button-container {
      width: 150px;
      @media only screen and (max-width: 1440px) {
        width: 120px;
      }
      @media only screen and (max-width: 1024px) {
        width: 115px;
      }
    }
  }

  .table-grid {
    .select-header-cell {
      display: flex;
      justify-content: center;
      background: $dashboard-background;
      height: 90px;
      .MuiDataGrid-columnHeaderDraggableContainer {
        width: unset;
      }
    }
    .select-cell {
      background-color: $dashboard-background;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center !important;
    }
    .MuiDataGrid-root {
      border-color: $dashboard-background;
      .MuiDataGrid-cell {
        border-color: $dashboard-background;
      }
      .MuiDataGrid-columnsContainer {
        border-color: $dashboard-background;
      }
    }
  }
}

.simple-action-button-edit {
  border: 1px solid $light-blue !important;
  padding: 2px 37px !important;
}
