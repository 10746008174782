@import '../../assets/scss/variables';

.main-content {
    .community-page {
        .go-to-view {
            text-decoration: none;
            color: unset;
            &:hover {
                color: $light-blue;
            }
        }
        .table-row-actions {
            justify-content: space-around;
        }
    }
}