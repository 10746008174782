@import '../../assets/scss/variables';

.modal-terms {
    .terms-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        position: relative;
        width: 420px;
        max-height: 835px;
        background: $white;
        margin: 68px auto;
        h2 {
            font-size: 20px;
        }
        .text-container {
            width: 70%;
            text-align: right;
            font-size: 18px;
            max-height: inherit;
            overflow-y: auto;
            padding-left: 20px;
            ul {
                list-style-type: none;
            }
        }
        .is-read {
            .MuiFormControlLabel-label {
                font-size: 13px;
            }
        }
    }
    .terms-close {
        display: flex;
        justify-content: flex-end;
        width: 80%;
        .icon-close {
            cursor: pointer;
            width: 23px;
        }
    }
    .terms-padding {
        padding: 20px 0;
        margin-left: 0;
    }
    .button-container {
        padding: 13px 0 63px;
    }
}