@import "../../assets/scss/variables";

* {
  outline: none !important;
}

.field-label {
  font-size: 12px;
}

.field-error {
  font-size: 14px;
  color: $error-red;
  white-space: pre-line;
}

.element-faded {
  opacity: 0.5;
}

.no-actions {
  pointer-events: none;
}

.element-invisible {
  opacity: 0;
  pointer-events: none;
}

.hide-element {
  display: none;
}

.main-content {
  padding: 100px 75px 60px;
  height: inherit;
  width: calc(100% - 60px);
  z-index: 0;
  margin-left: 60px;
  position: static !important;
  background-color: $dashboard-background;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  @media only screen and (max-width: 768px) {
    padding: 85px 30px 60px;
    width: 93.4%;
    margin-left: 6.6%;
  }
  @media only screen and (max-width: 736px) {
    margin-left: 14.3%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .simple-action-button {
    border: 1px solid $light-blue;
    padding: 2px 43px;
  }

  .text-disabled {
    color: $dark-gray;
  }

  h3 {
    font-size: 1.125em;
    font-weight: normal;
  }

  .break-text {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  .main-wrapper {
    padding: 30px 75px 60px;
    @media only screen and (max-width: 768px) {
      padding: 15px 32px 30px;
    }
    .main-wrapper {
      padding: 1.7% 4.2% 3.6%;
      background: $white;
      border: 1px solid $border-gray;
      margin-bottom: 50px;
    }
  }

  &.main-content-tabs {
    margin-top: 61px;
    padding: 0 0 60px;
  }

  &.edit-community,
  &.view-community {
    overflow-y: auto;
  }

  .page-heading-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .export-button {
      cursor: pointer;
      margin: 0 15px;

      &-text {
        margin: 0 5px;
        color: $light-blue;
      }

      .icon {
        img {
          width: 1.42rem;
          height: 1.42rem;
        }
      }
    }
  }

  .page-heading {
    font-weight: bold;
    font-size: 1.6em;
  }

  .section-heading {
    font-weight: normal;
    font-size: 1.125rem;
  }

  .supplier-page {
    display: flex;
    flex-direction: row;
    padding-top: 30px;

    .form {
      padding-left: 50px;
    }

    .action-box {
      margin-top: 30px;
    }
  }

  .card-wrapper {
    padding: 2.3%;
    .card-heading {
      margin-bottom: 15px;
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  .info-section-title {
    color: $light-blue;
    line-height: 25px;
  }

  .text-end {
    text-align: end;
  }

  .select-preview-filter {
    width: 150px;
    font-size: 0.9rem;
    @media only screen and (max-width: 1024px) {
      width: 130px;
    }
  }

  .toggle-buttons-group {
    button {
      border: 1px solid $light-blue;
      color: $black;
      padding: 6px 30px;
      text-transform: capitalize;
      font-size: 1em;
      @media only screen and (max-width: 1440px) {
        padding: 6px 20px;
      }
      &.Mui-selected {
        background: $light-blue;
        color: $white;
      }
    }
  }

  .field-label {
    font-size: 12px;
  }

  .field-error {
    font-size: 14px;
    color: $error-red;
  }

  .multi-select {
    .dropdown-container {
      border-radius: 0;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-color: $dark-gray;

      &:focus-within {
        box-shadow: none;
      }

      .dropdown-heading {
        padding: 0;
      }
    }

    &.disabled {
      .dropdown-container {
        border-color: $dark-gray;
        border-style: dotted;

        &:focus-within {
          border-color: $dark-gray;
        }

        .dropdown-heading-value {
          color: $dark-gray;
        }
      }
    }
  }
}

.table-pagination {
  direction: ltr;
}

.table-grid,
.helperContainerClass {
  width: 100%;
  background: $white;

  .MuiIconButton-root {
    padding: 4px;
  }

  .MuiDataGrid-window,
  .table-column {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  .MuiDataGrid-root {
    .MuiDataGrid-columnHeaderTitleContainer,
    .MuiDataGrid-colCellTitleContainer {
      padding: 0;
    }
  }

  .hidden-cell {
    background: $dashboard-background;
    height: 90px;
    pointer-events: none;
  }

  .table-row-title {
    overflow-wrap: anywhere;
    word-wrap: anywhere;
    white-space: normal;
    line-height: 1.2;
    font-size: 0.9em;
  }

  .cell-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.flex-end {
      justify-content: flex-end;
    }

    .icon {
      &:hover {
        filter: $filter-light-blue;
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }

  .MuiDataGrid-columnSeparator {
    display: none;
  }

  .table-column {
    font-size: 0.9em;
    font-weight: 600;
    color: $dark-gray;
    white-space: initial;
    line-height: 1.2;
    &.sorted {
      color: $light-blue;
    }
  }

  .table-row-split {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table-row-image-container {
    height: 60px;
    width: 60px;
    @media only screen and (max-width: 768px) {
      height: 50px;
      width: 50px;
    }
    &.round {
      border-radius: 50%;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 5px;
    }
  }

  .table-row-image {
    height: 60px;
    width: 60px;
    @media only screen and (max-width: 768px) {
      height: 50px;
      width: 50px;
    }
    border-radius: 5px;
    object-fit: cover;
    &.round {
      border-radius: 50%;
    }

    &.article-template-3 {
      background-image: none !important;
      background-color: $white;
      box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.2);
      margin: 10px 0;
    }
  }

  .table-row-scheduled {
    font-size: 12px;
    color: $light-blue;
  }

  .table-row-double {
    margin-top: 17px;

    div {
      line-height: 1.5;
    }
  }

  .table-row-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    &.approve-reject {
      justify-content: space-between;
    }
    .table-row-checkIcon {
      display: none;
    }

    &:hover {
      .table-row-checkIcon {
        display: unset;
        fill: $light-blue;
        transform: scale(1.1);
      }

      .table-row-actionIcon {
        fill: $light-blue;
        transform: scale(1.1);
      }
    }

    &.checked {
      .table-row-checkIcon {
        display: unset;
      }

      .table-row-actionIcon {
        height: 26px;
        width: 26px;
        border: 1px solid $light-blue;
        border-radius: 2px;
        fill: $light-blue;
      }
    }
    .article-item {
      button {
        padding: 4px 22px;
      }
    }
  }
}

.information-message {
  font-size: 12px;
  color: $light-blue;
}

.text-center {
  width: 100%;
  text-align: center;
}

.text-left {
  width: 100%;
  text-align: left;
}

.text-right {
  width: 100%;
  text-align: right;
}

.radio-group-label {
  margin-top: 30px;
}

.uppercase {
  text-transform: uppercase !important;
}

.action-menu {
  .MuiListItem-button {
    border-bottom: 2px solid $dashboard-background;
    padding: 6px;

    &:last-child {
      border-bottom: 0;
    }

    .menu-item {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .icon {
      filter: $filter-dark-gray;
      padding: 0 2px;
    }

    &:hover {
      background: $white;

      .menu-item {
        background: $dashboard-background;
      }

      .icon {
        filter: $filter-light-blue;
      }
    }
  }

  .MuiList-padding {
    padding: 0;
  }

  .view-link {
    text-decoration: none;
    color: $dark-blue;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
  }
}

.skylight-dialog {
  background-color: $white !important;
  width: 400px !important;
  margin-top: -200px !important;
  margin-left: -215px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem !important;
  border-radius: 3px !important;

  .skylight-close-button {
    cursor: pointer !important;
    position: absolute !important;
    font-size: 2.6rem !important;
    right: 30px !important;
    top: 15px !important;
  }

  .action-button {
    margin-top: 40px;
    text-transform: capitalize;
  }
}

// Buttons
.link-btn {
  cursor: pointer;
  margin: 0 25px !important;
  color: unset !important;
}

.search-window-slide-popup-container {
  height: 100vh;
  width: 0;
  background: $white;
  opacity: 0;
  z-index: 11;
  margin: 0 1px;
  transition: width 0.5s ease-out, opacity 0.6s;
  position: absolute;
  left: 61px;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    left: 50px;
  }
  &.opened {
    width: 305px;
    opacity: 1;
  }

  .search-window-slide-popup {
    display: flex;
    justify-content: center;
    height: 100%;
    font-size: 24px;
    width: 305px;
  }
}

.d-flex {
  display: flex;
  &.justify-start {
    justify-content: start;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.justify-around {
    justify-content: space-around;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.justify-evenly {
    justify-content: space-evenly;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.align-center {
    align-items: center;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.flex-row {
    flex-direction: row;
  }
  &.flex-column {
    flex-direction: column;
  }
}

.range-date-picker {
  position: relative;
  .dislayed-dates {
    cursor: pointer;
  //   opacity: 0.4;
    border-bottom: 1px solid $black;
    span {
      margin: 0 5px;
    }
    &:hover {
      color: $light-blue;
      border-color: $light-blue;
    }
  }
  .range-datepicker {
    position: absolute;
    top: 30px;
    right: 0;
  //   cursor: not-allowed !important;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;

  &.flex-column {
    flex-direction: column;
  }
}

.w-100 {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;

  &.link {
    color: $light-blue;

    &.no-underline {
      text-decoration: none;
    }
  }
}

.edit-row-container {
  display: flex;
  align-items: center;
  height: 73px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .table-row-checkIcon:hover {
    filter: unset !important;
  }

  .select-type {
    &.MuiInput-underline {
      &::before,
      &::after {
        bottom: 2px;
      }
    }
  }

  .error-field {
    .MuiInput-underline,
    &.MuiInput-underline {
      &::before,
      &::after {
        border-bottom-color: $error-red;
      }
    }
  }

  .error-box {
    border-bottom: 2px solid $error-red;
  }

  .MuiInputBase-root {
    width: 90%;
    margin: 0;
  }

  .select-underline {
    &::before,
    &::after {
      bottom: 2px;
    }
  }

  .MuiFormControl-marginNormal {
    margin: 0;
  }

  input {
    font-size: 0.875rem;
  }

  .upload-image-container {
    position: relative;
    cursor: pointer;

    .upload-icon {
      position: absolute;
      filter: $filter-white;

      &.edit {
        filter: $filter-light-blue;

        &:hover {
          filter: $filter-light-blue;
        }
      }

      &:hover {
        filter: $filter-light-blue;
      }
    }
  }

  .table-row-image {
    height: 60px;
    width: 60px;
    border-radius: 5px;
    filter: brightness(50%);
    object-fit: cover;
    @media only screen and (max-width: 768px) {
      height: 50px;
      width: 50px;
    }
    &.round {
      border-radius: 50%;
    }
  }

  .table-row-checkIcon-container {
    display: flex;
    align-items: center;
    height: 100%;
    .icon-container {
      padding: 20px;
      width: 22px;
      height: 22px;
      cursor: pointer;
      &:hover {
        .table-row-checkIcon {
          display: inline-block;
        }
      }
      &.checked {
        .table-row-checkIcon {
          display: inline-block;
        }
        &:hover {
          filter: $filter-light-blue;
        }
      }
    }

    .table-row-checkIcon {
      display: none;
      cursor: pointer;
    }

    &.edit-user {
      justify-content: center;
    }
  }

  .save-icon {
    cursor: pointer;

    &:hover {
      filter: $filter-light-blue;
    }
  }
}

.rmsc .dropdown-heading .dropdown-heading-value .gray {
  display: none;
}

.hover-item {
  cursor: pointer;
  &.grab-icon {
    cursor: grab;
    .icon {
      cursor: grab;
    }
  }
  &:hover {
    filter: $filter-light-blue;
  }
  &:disabled {
    filter: $filter-dark-gray;
  }
}

.Mui-disabled {
  pointer-events: none;
}

.display-flex {
  display: flex;
}

.justify-content-end {
  justify-content: flex-end;
}

.text-end {
  text-align: end;
}

.text-start {
  text-align: start;
}

.MuiDataGrid-iconSeparator {
  display: none !important;
}

.community-rtl {
  // Dashboard
  .main-content {
    margin-left: unset;
    margin-right: 60px;
    @media only screen and (max-width: 768px) {
      margin-right: 6.6%;
    }
    @media only screen and (max-width: 736px) {
      margin-right: 14.3%;
    }
    // Table grids everywhere
    .table-grid {
      direction: ltr;
      .approve-reject,
      .cell-actions {
        flex-direction: row-reverse;
      }
      .table-row-double {
        text-align: right;
      }
      .table-row-date {
        direction: rtl;
      }
      &.drag-and-drop-table {
        direction: rtl;
        th,
        td {
          text-align: right;
          &.text-center {
            text-align: center;
          }
        }
      }
      .MuiDataGrid-cell,
      .MuiDataGrid-columnHeaderTitleContainer,
      .MuiDataGrid-colCellTitleContainer {
        justify-content: end;
        flex-direction: row-reverse;
      }
    }
  }

  .draft-item-container {
    .draft-into-wrapper {
      .short-info-text,
      .tag-text {
        margin-left: unset;
        margin-right: 19px;
      }
    }

    .btn-with-icon-wrapper {
      margin-left: unset;
      margin-right: 48px;
      transform: rotateY(180deg);
    }
  }

  .event-item-container .event-content-wrapper {
    margin-left: unset;
    margin-right: 19.5px;
  }

  .activity-item-container {
    padding-left: unset;
    padding-right: 3px;

    .activity-text-wrapper {
      margin-left: unset;
      margin-right: 35px;
    }
  }

  .legend-list-wrapper {
    margin-left: unset;
    margin-right: 30px;

    .legend-item .legend-label {
      padding-left: unset;
      padding-right: 26.5px;
    }
  }

  // Form Labels
  .MuiFormLabel-root {
    right: 0;
    left: unset;
  }

  // Left Side Bar
  .left-side-bar-container {
    right: 0;
    left: unset;
  }

  // My messages
  .my-messages-page .section .item-row .avatar {
    margin-right: unset;
    margin-left: 25px;
  }

  // My Account
  .account-page ol {
    padding-left: unset;
    padding-right: 30px;
  }

  // Main Navigation buttons
  .top-nav-bar-container {
    .top-nav-links-container {
      padding-left: unset;
      padding-right: 35px;
      .top-nav-links-wrapper {
        width: 33%;
      }
      // Plus Icon
      .plus-icon-wrapper {
        right: unset;
        left: 23%;
        @media only screen and (max-width: 768px) {
          left: 27%;
        }
      }
    }
  }
  // Navigation sub buttons
  .MuiTab-root {
    margin-right: unset;
    margin-left: 40px;
    @media only screen and (max-width: 768px) {
      margin-left: 20px;
    }
  }

  // Search Container
  .search-window-slide-popup-container {
    left: unset;
    right: 61px;
    @media only screen and (max-width: 768px) {
      right: 50px;
    }
  }

  // Cell fields
  .MuiDataGrid-cellLeft,
  .MuiDataGrid-cell--textLeft {
    text-align: unset !important;
  }

  // Preview container on Creates
  .create-preview .create-grid .preview-container {
    .preview-image-wrap .preview-icons {
      right: unset !important;
      left: 20px;
    }

    .preview-text {
      text-align: unset !important;
    }
  }

  .preview-image-container {
    .preview-icons {
      right: unset !important;
      left: 10px;
    }

    .preview-text {
      text-align: unset !important;
    }
  }

  // Upload Picture
  .upload-container .upload-icon-container {
    right: unset;
    left: -15px;
  }

  // Editor
  .tox-toolbar__primary {
    justify-content: flex-end;
  }

  .rmsc .options {
    padding: 0;

    span {
      margin: 0 5px;
    }
  }

  // Calendar
  .MuiPickersBasePicker-pickerView {
    .MuiIconButton-label {
      .MuiSvgIcon-root {
        transform: rotateY(180deg);
      }
    }
  }

  // Select dropdown icon
  .MuiSelect-select.MuiSelect-select {
    padding-right: unset;
    padding-left: 24px;
  }

  // Select dropdown icon
  .MuiAutocomplete-inputRoot.MuiAutocomplete-inputRoot {
    padding-right: unset !important;
    padding-left: 56px !important;
    .MuiAutocomplete-endAdornment {
      right: unset;
      left: 0;
    }
  }

  .MuiSelect-icon {
    right: unset;
    left: 0;
  }

  // User edit
  .user-edit .MuiMenu-paper {
    left: unset !important;
    right: 136px !important;
  }

  .community-user-edit .MuiMenu-paper {
    left: 84px !important;
  }

  //Terms and Privacy
  .modal-terms {
    .terms-padding {
      margin-left: 16px;
      margin-right: 0;
    }
  }

  // User List - Active/Nonactive buttons
  .users-page {
    .toggle-buttons-group {
      button {
        &:first-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        &:last-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    }
  }

  // Settings -> General
  .settings-page {
    .phone-container {
      .phone-container-top-nav {
        left: unset;
        right: 23px;
        @media only screen and (max-width: 768px) {
          right: 17px;
        }
      }

      .phone-container-bottom-nav {
        left: unset;
        right: 38px;
        @media only screen and (max-width: 768px) {
          right: 30px;
        }
      }

      .phone-container-logo {
        left: unset;
        right: 140px;
        @media only screen and (max-width: 768px) {
          right: 104px;
        }
      }
    }

    .benefits-preview-container {
      .benefits-preview {
        left: unset;
        right: 21px;
      }
    }
  }

  // Multi-select
  .multi-select {
    input[type="checkbox"] {
      margin: 0 5px;
    }

    .dropdown-content {
      li {
        margin-right: -40px;
      }
    }
  }

  .MuiInputLabel-shrink {
    transform-origin: top right;
  }

  // Sign up - checkbox
  .is-have-read {
    margin-right: -11px;
    margin-left: 16px;
  }

  // Users - Import Users
  .modal-preview.import-from-file {
    .person-images {
      div:first-of-type,
      div:last-of-type {
        img {
          transform: scaleX(-1);
        }
      }
      .close-container {
        position: absolute;
        top: 20px;
        left: 20px !important;
        right: auto !important;
        .icon-close {
          cursor: pointer;
          font-size: 2rem;
        }
      }
    }
  }
  // Tables alignment && HelperText alignment
  .table-row-title,
  .MuiFormHelperText-root {
    text-align: right;
    direction: rtl;
    &.email {
      direction: ltr;
    }
  }

  // Radio/Checkbox alignment
  .MuiFormControlLabel-root {
    margin-left: 16px;
    margin-right: -11px;
  }

  // Community Logo Nav
  .community-icom {
    border-right: unset;
    border-left: 1px solid $grey;
  }

  // Left Notification icon
  .left-side-bar-container {
    border-right: unset;
    border-left: 1px solid $grey;
    .MuiBadge-badge {
      right: -5px;
    }
  }
  .sketch-picker {
    padding: 10px 10px 5px;
    .flexbox-fix {
      &:nth-child(4) {
        padding: 0px 0px 20px 10px !important;
      }
    }
  }
}

.MuiButtonBase-root.MuiButton-root {
  font-size: 1.1em;
}

// Resolution below 768px
@media only screen and (max-width: 768px) {
  .MuiAppBar-root.MuiAppBar-root {
    padding: 0 35px;
  }
  .MuiTabs-flexContainer {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .MuiButtonBase-root.MuiTab-root {
    margin-right: 15px;
    .MuiTab-wrapper {
      font-size: 12px;
    }
  }
  .MuiMenuItem-root {
    font-size: 14px !important;
    min-height: 36px !important;
  }
}
