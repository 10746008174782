@import "../../../assets/scss/variables";

.waiting-for-approval {
    .link-button-container {
        font-weight: bold;
        margin: 0 15px;
    }

    .approve-all {
        font-weight: bold;
    }

    .export-button {
        cursor: pointer;
        color: $light-blue;
        padding: 0;
        .icon {
            img {
                width: 1.42rem;
                height: 1.42rem;
                margin: 0 10px;
            }
        }
    }
    
    .select-dropdown {
        width: 150px;
        height: 63px;
        .MuiInput-input {
            text-align: right;
        }
    }
}
