@import "../../../../../assets/scss/variables";
.successful-article-container {
    .back-icon-container {
        padding: 16px 16px 18px;
    }
    .image-holder {
        position: relative;
        text-align: center;
        img {
            width: 75%;
            height: auto;
        }
    }
    p {
        margin: 5px;
    }
    .great {
        font-size: 20px;
        color: $light-blue;
    }
    .message {
        font-size: 14px;
        margin-bottom: 55px;
        p {
            width: 60%;
            text-align: center;
        }
    }
}

.community-rtl {
    .successful-article-container { 
        .icon {
            transform: rotateZ(180deg);
        }
    }
}