@import "../../../assets/scss/variables";

.table-grid {
    margin-bottom: 50px;
    .empty-header-cell {
        pointer-events: none;
        height: 19px;
    }
    .banner-action {
        margin-top: 10px;
        cursor: pointer;
        &:hover {
            filter: $filter-light-blue;
            transform: scale(1.1);
        }
    }
}

.no-ads {
    position: relative;
    text-align: center;
}
