@import '../../../assets/scss/variables';

.reports-page {
    .main-wrapper {
        .purchase-chart {
            margin-bottom: 0;
            border-bottom: 0;
        }
    }
    .chart-legend {
        background-color: $light-gray;
        border: 1px solid $border-gray;
        margin-bottom: 50px;
        .legend {
            margin: 25px;
            .dot {
                width: 12px;
                height: 12px;
                margin: 0 5px;
                border-radius: 50%;
                &.blue {
                    background-color: $light-blue;
                }
                &.yellow {
                    background-color: $yellow;
                }
                &.cyan {
                    background-color: $cyan;
                }
            }
        }
    }
}