.draft-list-container {
    padding: 4.7%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    .drafts-title {
        font-size: 18px;
        margin-bottom: 30px;
    }
    .scroll-container {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
    }
}
