@import "../../assets/scss/variables";
.top-nav-bar-container {
    background-color: $white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // max-width: 1920px;
    height: 60px;
    display: flex;
    z-index: 10;
    border-bottom-style: solid;
    border-width: 1px;
    border-bottom-color: $grey;
    .community-icom {
        cursor: pointer;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right-style: solid;
        border-width: 1px;
        border-right-color: $grey;
        @media only screen and (max-width: 768px) {
            width: 50px;
        }
        img {
            padding-left: 2.5px;
        }
    }
    .community-icon-wrapper {
        width: 61px;
        height: 60px;
    }
    .top-nav-links-container {
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-left: 4%;
        @media only screen and (max-width: 736px) {
            justify-content: flex-end;
        }
        .top-nav-links-wrapper {
            display: flex;
            justify-content: space-between;
            width: 41%;
            box-sizing: border-box;
        }
        .nav-bar-item {
            position: relative;
            color: $light-blue;
            cursor: pointer;
            font-size: 0.95rem;
            font-family: $community-font;
            font-style: normal;
            font-weight: 600;
            margin-right: 4%;
            transition: all 0.2s ease-in-out;
            text-decoration: none;
            text-transform: uppercase;
            @media only screen and (max-width : 768px) {
                font-size: 0.7rem;
            }
            &:hover {
                color: darken($light-blue, 20%);
            }

            &:after {
                content: "";
                border-bottom: 2px solid darken($light-blue, 20%);
                left: 0;
                position: absolute;
                top: 110%;
                transition: all 0.2s ease-in-out;
                width: 0;
               opacity: 0;
            }

            &:hover:after {
                opacity: 1;
                left: 0;
                width: 100%;
            }

            &.active {
                color: $black;
            }
        }

        .plus-icon-wrapper {
            position: absolute;
            right: 23%;
            height: 67px;
            width: 67px;
            top: 24px;
            transition: box-shadow 250ms linear;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            @media only screen and (max-width : 768px) {
                width: 50px;
                height: 50px;
                top: 35px;
            }
            &:hover {
                box-shadow: 0px 0px 22px rgba(16, 13, 13, 0.4);
            }
            .icon,
            img {
                box-shadow: 0px 0px 12px rgba(84, 79, 79, 0.13);
                height: 100%;
                width: 100%;
                border-radius: 50%;
                -moz-border-radius: 50%;
                -webkit-border-radius: 50%;
            }
        }
        .community-and-language {
            max-width: 23%;
            justify-content: space-around;
            .current-community {
                & > * {
                    margin: 0 5px;
                }
                span {
                    color: $light-blue;
                    font-size: 15px;
                    @media only screen and (max-width : 768px) {
                        font-size: 11px;
                    }
                }
                .community-logo {
                    width: 24px;
                    height: 24px;
                }
            }
            .select-language-container {
                filter: $filter-light-blue;
                width: 100px;
                .select-language {
                    width: 50px;
                    margin: 0;
                    &.MuiInput-underline {
                        &::before, &::after {
                            display: none;
                        }
                    }
                }
                @media only screen and (max-width: 768px) {
                    width: 45px;
                    .select-language {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}
