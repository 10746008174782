.create-user {
    .form-field {
        margin-bottom: 15px;
        &.type {
            margin: 25px 0 5px;
        }
        .multi-select {
            .MuiInputLabel-outlined {
                transform: translate(0px, 35px) scale(1);
            }
            .MuiInputLabel-shrink {
                transform: translate(0px, 0) scale(1);
            }
        }
    }
}
