.loader-wrapper {
    position: absolute;
    z-index: 5;
}
.fade-in {
    opacity: 1;
}
.fade-in.visible {
    transition: opacity 500ms ease-in-out;
    opacity: 0;
}
