.image-container {
    height: 60px;
    width: 60px;
    transition: all ease 0.2s;
    img {
        border-radius: 3px;
        height: 60px;
        width: 60px;
    }
    @media only screen and (max-width : 768px) {
        height: 50px;
        width: 50px;
        img {
            height: 50px;
        width: 50px;
        }
    }
}
