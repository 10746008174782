@import "../../../assets/scss/variables";

.primary-box-container {
    margin-top: 24px;
    height: 492px;
    // width: 50%;
    background-color: $white;
    border: 1px solid #e6eaee;
    box-sizing: border-box;
}
