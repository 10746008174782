@import "../../../assets/scss/variables";

.sales-page {
  
  .table-grid {
    margin-bottom: 50px;
    .cell-category {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
    .no-benefits {
        text-align: center;
    }
}

input[type="number"] {
  -moz-appearance: textfield;
}