@import "../../../assets/scss/variables";

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .datepicker {
    position: relative;
    .dislayed-dates {
    //   cursor: not-allowed;
      cursor: pointer;
      border-bottom: 1px solid $black;
      span {
        margin: 0 5px;
      }
      &:hover {
        color: $light-blue;
        border-color: $light-blue;
      }
    }
    .range-datepicker {
      position: absolute;
      top: 30px;
      right: 0;
    //   cursor: not-allowed !important;
    }
  }
}
