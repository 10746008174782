.general-settings {
  .popover {
    position: absolute;
    z-index: 2;
    top: 40vh;
    left: 40vw;
  }
  
  .cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .proportion-container {
    position: absolute;
    bottom: -16px;
    font-weight: bold;
    font-size: 13px;
    @media only screen and (max-width: 1024px) {
      font-size: 10px;
    }
  }

  .image-upload {
    position: absolute;
    margin-top: 20px;
    .image-wrap {
      text-align: center;
      position: relative;
      height: 250px;
      width: 250px;
      @media only screen and (max-width: 768px) {
        width: 187.5px;
        height: 187.5px;
      }
    }
  }
}
