@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/common";

.create-article.banner {
  .create-preview {
    .create-grid {
      .preview-container {
        position: relative;
        height: fit-content;
        .fixed {
          position: fixed;
          top: 25%;
          right: 9.5%;
          box-shadow: 0px 0px 50px rgba(16, 13, 13, 0.4);
          z-index: 5;
          @media only screen and (max-width: 768px) {
            right: 5.5%
          }
        }
        .preview-image {
          position: relative;
          height: 376px;
          width: 352px;
          padding: 0;
          display: flex;
          align-items: flex-end;
          background-size: cover;
          background-position: center;
          @media only screen and (max-width: 1440px) {
            height: 300.8px;
            width: 281.6px;
          }
          @media only screen and (max-width: 1024px) {
            height: 235px;
            width: 220px;
          }
          .crop-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
        .rotating-banner {
          position: absolute;
          width: 352px;
          cursor: grab;
          bottom: 10px;
          @media only screen and (max-width: 1440px) {
            width: 281.6px;
          }
          @media only screen and (max-width: 1024px) {
            width: 220px;
          }
          &:active {
            cursor: grabbing;
          }
          .slide {
            margin: 0 8px;
          }

          .preview-category {
            position: absolute;
            top: -10px;
          }
          .trans-border {
            position: relative;
            padding: 3% 4%;
            .banner {
              background-color: rgba(0, 0, 0, 0.5);
              height: 156px;
              position: relative;
              @media only screen and (max-width: 1440px) {
                height: 124.8px;
              }
              @media only screen and (max-width: 1024px) {
                height: 97.5px;
              }
              .preview-text {
                position: relative;
                bottom: unset;
                left: unset;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding: 8% 10%;
                display: flex;
                flex-direction: column;
                .preview-header-date {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  height: 100%;
                  &::-webkit-scrollbar {
                    display: none;
                  }
                  .preview-header {
                    height: 65px;
                    margin-bottom: 0;
                  }
                }
                .preview-header,
                .preview-date {
                  text-shadow: none;
                }
                .preview-date {
                  color: $white;
                }
              }
              p {
                color: $white;
              }
            }
          }
        }
        .preview-text {
          position: absolute;
          bottom: 25px;
          left: 30px;
          text-align: left;
          width: 84%;
          .preview-category {
            background: $cyan;
            width: fit-content;
            height: fit-content;
            padding: 5px 20px;
            font-size: 0.8em;
            max-width: 85%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          p {
            color: $white;
          }
          .preview-header {
            font-size: 1.05em;
            overflow-wrap: break-word;
            word-wrap: break-word;
            hyphens: auto;
            text-shadow: 1px 1px 2px #000;
          }
          .preview-date {
            font-size: 0.8em;
            text-shadow: 1px 1px 2px #000;
          }
        }
      }
    }
  }

  .preview-image-container {
    position: relative;
    box-shadow: 0px 0px 20px rgba(16, 13, 13, 0.4);
    width: 100%;
    height: 51%;
    .preview-image {
      width: 100%;
      height: 100%;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
    .rotating-banner {
      position: absolute;
      height: 50%;
      width: 228px;
      @media only screen and (max-width: 1440px) {
        width: 142.5px;
      }
      @media only screen and (max-width: 768px) {
        width: 113.5px;
      }
      cursor: grab;
      bottom: 0;
      &:active {
        cursor: grabbing;
      }
      .banner-container {
        display: flex;
        align-items: center;
        position: relative;
        width: fit-content;
        width: min-content;
        width: inherit;
      }
      .trans-border {
        width: inherit;
        position: relative;
        padding: 4%;
        height: inherit;
        box-sizing: border-box;
        .banner {
          background-color: rgba(0, 0, 0, 0.5);
          height: inherit;
          .preview-text {
            position: relative;
            text-align: unset;
            left: unset;
            bottom: 0;
            width: 100%;
            height: inherit;
            box-sizing: border-box;
            padding: 1% 8%;
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            .preview-header-date {
              overflow-x: auto;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              height: 75%;
              &::-webkit-scrollbar {
                display: none;
              }
            }
            .preview-category {
              position: absolute;
              top: -8px;
            }
            .preview-date {
              color: $white;
            }
            .preview-header,
            .preview-date {
              text-shadow: none;
              color: $white;
            }
          }
        }
      }
    }
    .preview-short-text {
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      font-size: 0.6rem;
      height: inherit;
      .preview-text-header {
        font-weight: 600;
      }
    }
    .preview-text {
      position: absolute;
      bottom: 10px;
      left: 20px;
      text-align: left;
      width: 81%;
      .preview-category {
        background: $cyan;
        width: fit-content;
        height: fit-content;
        padding: 2% 8%;
        font-size: 0.6rem;
        max-width: 85%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      p {
        color: $white;
        margin: 5% 0;
      }
      .preview-header {
        font-size: 0.6rem;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        text-shadow: 1px 1px 2px #000;
      }
      .preview-date {
        font-size: 0.6rem;
        text-shadow: 1px 1px 2px #000;
      }
    }
  }
}

.community-rtl {
  .create-article.banner {
    .create-preview {
      .create-grid {
        .preview-container {
          .fixed {
            right: unset;
            left: 9.5%;
            @media only screen and (max-width: 768px) {
              left: 6%;
            }
          }
        }
      }
    }
  }
}
