@import "../../assets/scss/variables";

#spinner-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  background: rgba(102, 101, 101, 0.5);
}

.spinner-text {
  position: absolute;
  top: 53%;
  right: 50%;
  color: $white;
}

.lds-ring {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 45%;
  right: 45%;
  @media only screen and (max-width: 768px) {
    right: 38%;
  }
  &.search-spinner {
    right: 38%;
  }
  &.edit-spinner {
    top: 20%;
    width: 40px;
    height: 40px;
    &.lds-ring div {
      width: 40px;
      height: 40px;
      border-width: 5px;
    }
  }
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid $light-blue;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $light-blue transparent transparent transparent;
  @media only screen and (max-width: 768px) {
    border-width: 6px;
  }
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
