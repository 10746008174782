@import "../../../assets/scss/variables";

.import-from-file-page {
  .import-message {
    margin: 0;
    font-weight: 600;
  }
  .select-container {
    margin: 0;
    .select-preview-filter {
      width: 200px;
    }
  }

  .import-from-file {
    text-align: center;
  
    .link {
      color: $light-blue;
      margin-top: 40px;
    }
    .note {
      display: block;
      margin-bottom: 40px;
  
      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      .button {
        margin: 0 5px;
        font-size: 14px;
      }
    }
    .view-link {
      text-decoration: none;
      color: white !important;
    }
    .cursor-upload {
      cursor: pointer;
    }
    .cursor-disabled {
      cursor: not-allowed;
    }
  }
}


.modal-preview {
  .uploaded-information-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 50%;
    height: 70%;
    background: $white;
    margin: 10% auto 0;
    border-radius: 5px;
    .close-container {
      display: none;
    }
    .uploaded-information {
      display: flex;
      justify-content: space-evenly;
      align-items: baseline;
      width: 100%;
      margin-bottom: 40px;
      h1 {
        font-size: 4.3rem;
        font-weight: 400;
      }
      p {
        font-size: 1.15rem;
      }
      div {
        width: 120px;
        & > * {
          margin: 0
        }
      }
    }
    .person-images {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 40px;
      div {
        width: 5vw;
        height: 5vw;
        &:first-child {
          width: 3vw;
        }
        &:last-child {
          width: 2.5vw;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .close-container {
      position: absolute;
      top: 20px;
      right: 20px;
      .icon-close {
          cursor: pointer;
          font-size: 2rem;
      }
  }
  }
  &.import-users {
    .notification-container {
      max-height: 300px;
      height: unset;
      .message-container {
        display: block;
        margin-top: 50px;
        max-height: 400px;
        overflow-y: auto;
      }
      .submit-button {
        margin: 20px 0;
      }
    }
  }
  .error-container-header  {
    margin-top: 30px;
    font-weight: 600;
  }
  .error-container {
    padding: 10px 0;
    width: 50%;
    border: 1px solid gray;
    border-radius: 5px;
    max-height: 20%;
    overflow-y: auto;
    overflow-x: hidden;
    .field-error {
      font-size: 16px;
      margin-right: 10px;
    }
  }
}
