@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.create-article {
  .create-preview {
    .create-grid {
      .grid-row {
        padding: 15px 0;
        .text-area {
          width: 100%;
          padding: 10px;
          resize: none;
        }
      }
    }
  }

  .proportion-container {
    position: fixed;
    font-weight: bold;
    color: $black;
    z-index: 10;
    margin: 5px 0;
    @media only screen and (max-width: 1024px) {
      font-size: 10px;
    }
  }

  .show-original-image-button {
    margin-bottom: 10px;
    button {
      font-size: 1em;
    }
  }

  .error-text {
    font-size: 18px;
  }

  .submit-buttons {
    .custom-btn {
      background: none;
      border: 0;
    }
    .link-button-container {
      cursor: unset;
      &:hover {
        background-color: unset;
      }
    }
  }
  #appear-in-label {
    margin-top: 16px;
  }

  input[type="checkbox"],
  input[type="radio"],
  .PrivateSwitchBase {
    position: relative !important;
  }

}

.tox-notifications-container,
.tox-statusbar__text-container {
  display: none !important;
}

.MuiPickersTimePickerToolbar-ampmSelection {
  margin: 0 20px !important;
}

.community-rtl .MuiPickersTimePickerToolbar-hourMinuteLabel {
  flex-direction: row-reverse !important;
}
.community-rtl .MuiPickersToolbar-toolbar {
  flex-direction: row-reverse !important;
}
