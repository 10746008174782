@import "../../assets/scss/variables";
.left-side-bar-container {
    background-color: $white;
    position: fixed;
    left: 0;
    height: 100%;
    width: 60px;
    top: 19px;
    padding-top: 55px;
    border-right-style: solid;
    border-width: 1px;
    border-right-color: $grey;
    z-index: 1;
    @media only screen and (max-width: 768px) {
        width: 50px;
    }
    .icons-wrapper {
        height: 100%;

        .icon-wrapper {
            height: 30px;
            margin-bottom: 38px;
            text-align: center;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            path {
                transition: all 0.2s ease-in-out;
            }
            .active {
                background: $light-gray;
                path {
                    fill: $black;
                }
            }
            &:hover {
                transform: scale(1.1);
                path {
                    fill: #3339e2;
                }
            }

            a {
                border-radius: 3px;
                display: flex;
                margin: 0 auto;
                align-items: center;
                justify-content: center;
                height: 40px;
                width: 40px;
            }
        }
    }
    .user-short-name {
        color: $white;
        font-family: $community-font;
        height: 60px;
        background-color: $light-blue;
        bottom: 0;
        position: fixed;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        @media only screen and (max-width: 768px) {
            height: 50px;
            width: 50px;
        }
        &:hover {
            background-color: darken($light-blue, 20%);
        }
    }
    .notifications-count {
        .MuiBadge-badge {
            top: -10px;
            right: 5px;
            transform: scale(0.8) translate(50%, -50%);
        }
    }
}
