.event-item-container {
    position: relative;
    .event-item-content-wrapper {
        opacity: 0;
        display: flex;
        position: absolute;
        cursor: pointer;
        z-index: 5;
        &:hover {
            .btn-with-icon-wrapper {
                img {
                    // animation-play-state: start;
                    // padding-left: 8px;
                }
            }

            .image-container {
                transform: scale(1.1);
                img {
               
                }
            }
        }
    }
    .event-name {
        font-size: 14px;
        // margin-left: 19.5px;
    }
    .splice-text {
        font-size: 14px;
        text-align: end;
    }
    .event-content-wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 19.5px;
        justify-content: space-between;
        .propgress-wrapper {
            width: 419px;
            // margin-left: auto;
        }
        .event-tickets-status {
            font-size: 14px;
            text-align: end;
        }
    }
    .event-item-loader-wrapper {
        cursor: default;
        z-index: 4;
        position: absolute;
    }
    .hide {
        transition: opacity 500ms ease-in-out;
        opacity: 0;
    }
    .fade-in {
        transition: opacity 500ms ease-in-out;
        opacity: 1;
    }
    .show {
        transition: opacity 500ms ease-in-out;
        opacity: 1;
    }
}
