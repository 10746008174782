@import "../../../../../assets/scss/variables";

.mobile-create-menu {
    position: absolute;
    bottom: 56px;
    width: 100%;
    height: calc(100% - 56px);
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    opacity: 0;
    z-index: -1;
    transition: all 0.5s ease-out;
    .content-container {
        position: absolute;
        width: 100%;
        bottom: -100%;
        left: 27px;
        height: 35%;
        transition: all 0.5s ease-out;
        .close-container {
            position: absolute;
            top: 10px;
            right: -35px;
            .icon-close {
                cursor: pointer;
                font-size: 26px;
                filter: $filter-white;
            }
        }
        .action {
            width: 100px;
            justify-content: space-between;
            margin: 10px 0;
            .action-icon-container {
                width: 46px;
                height: 46px;
                border-radius: 50%;
                background: $white;
                box-shadow: 0px 0px 22px rgba(16, 13, 13, 0.4);
                .action-icon {
                    filter: $filter-light-blue;
                    .icon {
                        width: 20px;
                    }
                }
            }
            .action-text {
                width: 39px;
                text-align: left;
                font-size: 14px;
                color: $white;
            }
        }
    }
    &.opened {
        opacity: 1;
        z-index: 2;
        .content-container {
            bottom: 80px;
        }
    }
}
