@import "../../assets/scss/variables";

.push-page {
  .table-grid {
    margin-bottom: 50px;
    .cell-category {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .cell-actions-header {
      width: 100%;
      // margin-left: 23px;
      &.single-action {
        text-align: right;
        margin-right: 9px;
      }
    }
    .table-row-actions {
      &.cell-actions {
        display: flex;
        justify-content: space-around;
        align-items: center;
        cursor: unset;
        &.single-action {
          width: 87%;
          justify-content: flex-end;
        }
      }
    }
  }
  .no-notifications {
    text-align: center;
  }
  &.edit {
    .save-edit {
      width: 84%;
    }
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}
