
.settings-page {
    .permissions-page {
        .content-grid {
            .associated-community {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: 62px;;
                .label {
                    top: 0;
                    position: absolute;
                    font-size: 12px;
                }
            }
            @media only screen and (max-width : 1024px) {
                .select-label {
                    font-size: 12px;
                }
                .select-permission-checkbox {
                    .MuiCheckbox-root {
                        margin: 0;
                        padding: 0;
                    }
                    .MuiIconButton-label {
                        margin: 0;
                    }
                }
            }
            @media only screen and (max-width: 768px) {
                .item-margin {
                    margin: 0;
                }
            }
        }
    }
}