@import "../../../assets/scss/variables";

.article-list {
    height: calc(100% - 56px);
    .no-items-container {
        height: 89%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .no-items {
        font-size: 14px;
    }
    .select-filter-container {
        width: 100%;
        padding: 8px 16px;
        box-sizing: border-box;
        .MuiInputBase-input {
            font-size: 14px;
        }
    }
    .list-container {
        width: 100%;
        .list-title {
            margin: 8px 16px;
            font-size: 14px;
        }
    }
}
