@import "../../../assets/scss/variables";

.table-grid {
    margin-bottom: 50px;
    .banner-action {
        margin-top: 10px;
        cursor: pointer;
        &:hover {
            filter: $filter-light-blue;
            transform: scale(1.1);
        }
    }
}
.rotating-banner-wrap {
    .select-preview-filter {
      margin-right: 20px;
    }
}

.no-banners {
    position: relative;
    text-align: center;
    div {
        position: absolute;
        left: 47%;
        top: 20px;
        color: $medium-blue;
    }
}
