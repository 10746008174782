.no-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 60px;

  p {
    margin-top: 25px;
    font-size: 1.5rem;
    line-height: 30px;
    margin-bottom: 0;
  }

  .note {
    font-size: 1.125rem;
    line-height: 50px;
  }
}
