.articles-statistics-container {
    padding: 4.7%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    .articles-statistics-title {
        padding-bottom: 50px;
        font-size: 18px;
        @media only screen and (max-width : 768px) {
            padding-bottom: 25px;
        }
    }
}
