@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/common";

.create-article {
  .create-preview {
    .create-grid {
      .grid-row {
        padding: 15px 0;
        .text-area {
          width: 100%;
          padding: 10px;
          resize: none;
        }
      }
    }
  }

  .error-text {
    font-size: 18px;
  }

  .submit-buttons {
    .custom-btn {
      background: none;
      border: 0;
    }
    .link-button-container {
      cursor: unset;
      &:hover {
        background-color: unset;
      }
    }
  }
  #appear-in-label {
    margin-top: 16px;
  }

  input[type="checkbox"],
  input[type="radio"],
  .PrivateSwitchBase {
    position: relative !important;
  }
}

.tox-notifications-container,
.tox-statusbar__text-container {
  display: none !important;
}

.MuiPickersTimePickerToolbar-ampmSelection {
  margin: 0 20px !important;
}

.community-rtl .MuiPickersTimePickerToolbar-hourMinuteLabel {
  flex-direction: row-reverse !important;
}
.community-rtl .MuiPickersToolbar-toolbar {
  flex-direction: row-reverse !important;
}

.create-article.article {
  .page-heading {
    margin-bottom: 4px;
  }
  h3 {
    margin: 0;
  }
  .new-article {
    display: flex;
    .article-type {
      margin: 0 20px;
      cursor: pointer;
      width: 12.5%;
      img {
        width: 100%;
        height: auto;
      }
      p {
        margin: 0;
      }
      &:hover,
      &.active {
        p {
          color: $light-blue;
        }
      }
    }
  }

  .create-options {
    margin-bottom: 50px;
  }

  .create-preview {
    .create-grid {
      .preview-container {
        position: relative;
        height: fit-content;
        .preview-image-wrap {
          position: fixed;
          top: 30%;
          right: 9.5%;
          width: 352px;
          height: 526px;
          z-index: 5;
          box-shadow: 0px 0px 50px rgba(16, 13, 13, 0.4);
          overflow: hidden;
          @media only screen and (max-width: 1440px) {
            width: 281.6px;
            height: 420.8px;
          }
          @media only screen and (max-width: 1024px) {
            width: 220px;
            height: 329px;
          }
          @media only screen and (max-width: 768px) {
            width: 176px;
            height: 263px;
            font-size: 11px;
          }
          .preview-image {
            position: relative;
            height: 100%;
            background-size: 100%;
            .crop-container {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            }
            &.template-2 {
              height: 230px;
              @media only screen and (max-width: 1440px) {
                height: 184px;
              }
              @media only screen and (max-width: 1024px) {
                height: 144px;
              }
              @media only screen and (max-width: 768px) {
                height: 115px;
              }
            }
            &.template-3 {
              background-color: $white;
            }
          }
          .preview-icons {
            display: flex;
            position: absolute;
            right: 7%;
            bottom: 6.4%;
            z-index: 6;
            .icon {
              margin: 0 5px;
              width: 25px;
              height: 25px;
              filter: $filter-white;
              @media only screen and (max-width: 1440px) {
                width: 20px;
                height: 20px;
              }
              @media only screen and (max-width: 1024px) {
                width: 15px;
                height: 15px;
              }
              &.favorite:hover {
                filter: $filter-red;
              }
              &.dots:hover {
                filter: $filter-dark-gray;
              }
            }
            &.template-2,
            &.template-3 {
              .icon {
                filter: $filter-black;
              }
            }
            &.template-3 {
              .icon {
                &.dots:hover {
                  filter: $filter-white;
                }
              }
            }
          }
          .preview-dates,
          .preview-author {
            position: absolute;
            right: 7%;
            bottom: 7.4%;
            font-size: 0.82rem;
          }
        }
        .preview-category {
          background: $cyan;
          width: fit-content;
          height: fit-content;
          padding: 5px 20px;
          font-size: 0.8rem;
          max-width: 85%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .template-1-container {
          position: absolute;
          bottom: 5%;
          left: 8.5%;
          // text-align: left;
          width: 84%;
          p {
            color: $white;
          }
          .preview-header {
            font-size: 1.05rem;
            overflow-wrap: break-word;
            word-wrap: break-word;
            hyphens: auto;
            text-shadow: 1px 1px 2px #000;
            font-weight: 600;
            &.sub-header {
              font-size: 0.9rem;
              max-height: 32px;
              overflow: hidden;
            }
          }
          .preview-date {
            font-size: 0.8rem;
            text-shadow: 1px 1px 2px #000;
          }
        }
      }
      .template-2-container {
        background: $dashboard-background;
        width: 100%;
        height: 300px;
        padding: 5% 10% 10%;
        margin-top: 0;
        box-sizing: border-box;
        @media only screen and (max-width: 1440px) {
          height: 240px;
        }
        @media only screen and (max-width: 1024px) {
          height: 187.5px;
        }
        @media only screen and (max-width: 768px) {
          height: 150px;
        }
        &::-webkit-scrollbar {
          display: none;
        }
        .preview-short-text {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 78%;
          overflow: hidden;
          &-header {
            font-weight: bold;
          }
          .preview-text-header {
            font-weight: 600;
          }
        }
      }
      .template-3-container {
        position: absolute;
        top: 5%;
        right: 8%;
        width: 84%;
        height: 90%;
        .preview-short-text {
          max-height: 72%;
          overflow: hidden;
        }
        .preview-text-header {
          font-size: 1.5rem;
          font-weight: 600;
          margin-bottom: 10%;
        }
        .preview-date {
          margin: 10% 0 4%;
          font-size: 0.8rem;
        }
      }
    }
  }

  .preview-image-container {
    position: relative;
    box-shadow: 0px 0px 20px rgba(16, 13, 13, 0.4);
    width: 100%;
    height: 69.6%;
    .preview-icons {
      display: flex;
      position: absolute;
      right: 10px;
      bottom: 15px;
      z-index: 6;
      .icon {
        margin: 0 5px;
        width: 15px;
        height: 15px;
        filter: $filter-white;
        &.favorite:hover {
          filter: $filter-red;
        }
        &.dots:hover {
          filter: $filter-dark-gray;
        }
      }
      &.template-2,
      &.template-3 {
        .icon {
          filter: $filter-black;
        }
      }
      &.template-3 {
        .icon.dots:hover {
          filter: $filter-white;
        }
      }
    }
    .preview-dates,
    .preview-author {
      position: absolute;
      right: 7%;
      bottom: 6%;
      font-size: 0.5rem;
    }
    .preview-image {
      width: 100%;
      height: 100%;
      &.template-2 {
        height: auto;
      }
      &.template-3 {
        background-color: $white;
      }
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
    .preview-short-text {
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      font-size: 0.6rem;
      .preview-text-header {
        font-weight: 600;
      }
      .preview-html {
        height: calc(100% - 25px);
        overflow: hidden;
      }
    }
    .preview-category {
      background: $cyan;
      width: fit-content;
      height: fit-content;
      padding: 2% 8%;
      font-size: 0.6rem;
      max-width: 85%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .template-1-container {
      position: absolute;
      bottom: 10px;
      left: 20px;
      width: 81%;
      p {
        color: $white;
        margin: 5% 0;
      }
      .preview-header {
        font-size: 0.6rem;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        text-shadow: 1px 1px 2px #000;
        font-weight: 600;
        &.sub-header {
          font-size: 0.5rem;
          max-height: 18px;
          overflow: hidden;
        }
      }
      .preview-date {
        font-size: 0.6rem;
        text-shadow: 1px 1px 2px #000;
      }
    }
    .template-2-container {
      background: $dashboard-background;
      padding: 12px 16px 0px;
      margin-top: -4px;
      height: 50%;
      .preview-text-header {
        font-size: 0.7rem;
      }
      .preview-short-text {
        height: 75%;
        overflow: hidden;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      .dangerous-html {
        font-size: 0.7rem;
        p {
          margin: 4% 0;
        }
      }
    }
    .template-3-container {
      position: absolute;
      top: 5%;
      right: 8%;
      width: 84%;
      height: 90%;
      .preview-short-text {
        max-height: 72%;
        overflow: hidden;
      }
      .preview-text-header {
        font-size: 0.95rem;
        margin-bottom: 5%;
      }
      .preview-date {
        margin: 10% 0 4%;
        font-size: 0.6rem;
      }
      .dangerous-html {
        font-size: 0.7rem;
        p {
          margin: 6% 0;
        }
      }
    }
  }
}

.community-rtl {
  .create-article.article {
    .create-preview {
      .create-grid {
        .preview-container {
          .preview-image-wrap {
            right: unset;
            left: 9.5%;
            @media only screen and (max-width: 768px) {
              left: 5%;
            }
          }
        }
      }
    }
  }
}
