@import "assets/scss/index";
.App {
  /* text-align: center; */
  box-sizing: border-box;
  // padding-left: 60px;
  background-color: #F6F7F8;
  scrollbar-color: #577BF9 #d3d3d3;
  scrollbar-width: thin;
  overflow: auto;
  scroll-padding-top: 200px;
}
.scrollbar-thumb{
  background: #577BF9!important;
  border-radius: 0px!important;
}
.space-top{
  height: 34px;
}
.modal-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 35px;
}
.modal-title{
  color: #577BF9;
  font-size: 18px;
  padding-bottom: 16px;
}
.modal-content{
  color: black;
  font-size: 16px;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d3d3d3; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #577BF9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #708efa;  
}

.important {
  margin-top: 10px;
  color: red;
}
