@import "../../../assets/scss/variables";

.sign-up {
    .is-have-read {
        .terms-btn {
            color: $black;
        }
        span {
            font-size: 0.9rem;
            text-decoration: underline;
        }
    }
}