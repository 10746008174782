@import "../../../assets/scss/variables";

.settings-archive {
  .main-wrapper-container {
    @media only screen and (max-width : 768px) {
      border: 10px solid transparent; 
    }
    .wrapper-image {
      position: relative;
      margin-bottom: 75px;
      img {
        position: absolute;
        left: 39.5%;
        top: -95px;
        @media only screen and (max-width : 1440px) {
          left: 36.5%;
          top: -70px;
        }
        @media only screen and (max-width : 1024px) {
          left: 29.5%;
        }
        @media only screen and (max-width : 768px) {
          left: 33%;
          width: 95.25px;
          top: -54px;
        }
      }
    }
    .wrapper-header {
      font-size: 18px;
      margin-bottom: 50px;
    }
  }
}

// .align-with-img-en {
//     padding-left: 35px;
// }
