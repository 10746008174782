@import "../../../assets/scss/variables";

.mobile-container {
    height: calc(100% - 56px);
    width: 100%;
    min-width: 320px;
    font-size: 12px;
    .mobile-content {
        width: 100%;
        height: calc(100% - 112px);
        overflow-y: auto;
        &.no-footer {
            height: calc(100% - 121px);
        }
        &::-webkit-scrollbar { 
            display: none;
        }
        .mobile-tabs-container {
            height: 100%;
            .app-bar {
                padding: 0;
                background: transparent !important;
                .MuiButtonBase-root.MuiTab-root {
                    width: 50%;
                    margin: 0;
                    &.Mui-selected {
                        color: $light-blue;
                    }
                    .MuiTab-wrapper {
                        font-size: 14px;
                    }
                }
            }
            .tab-panel {
                height: 100%;
                .tab {
                    height: 100%;
                }
            }
        }
    }
}
