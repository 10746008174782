@import '../../assets/scss/variables';

.upload-container {
    padding: 20px 0;
    border: 1px solid $dark-gray;
    border-radius: 5px;
    width: 100%;
    .upload-image-demo {
        width: 2.45rem;
    }
    &.hover:hover {
        .upload-image-demo, .upload-text {
            filter: $filter-light-blue;
        }
        .upload-icon-container {
            background-color: $light-blue;
            border: 1px solid $light-blue;
        }
    }
    label {
        position: relative;
        cursor: pointer;
    }
    .upload-image {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    .upload-text {
        margin-top: 10px;
        font-size: 13px;
    }
    .upload-icon-container {
        position: absolute;
        bottom: -15px;
        right: -15px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid $border-gray;
        background-color: $white;
    }
    &.mobile {
        border: none;
        padding: 0;
        margin-bottom: 10px;
        img {
          width: 21px;
          height: auto;
        }
        .upload-text {
            margin-top: 0;
            margin-left: 16px;
            margin-right: 16px;
        }
    }
}
