@import "../../../assets/scss/variables";

.push-page {
    .automated-page {
        .table-grid {
            .cell-actions-header {
                margin-left: 18px;
            }
        }
    }
    .page-heading {
        font-weight: bold;
        margin-bottom: 0;
    }

    .sub-header {
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .large-spacing {
        padding-left: 40px;
    }
}
