.dashboard-page {
  height: inherit;
  overflow-y: auto;
}

.dashboard-container {
  .dashboard-welcome-wrapper {
    padding-bottom: 45px;
  }
  .dashboard-actions-wrapper {
    .card-container {
      box-sizing: border-box;
    }
    .card-container:nth-of-type(2) {
      padding: 0 0.75% 0 1.5%;
    }
    .card-container:nth-of-type(3) {
      padding: 0 1.5% 0 0.75%;
    }
    @media only screen and (max-width: 736px) {
      .card-container {
        padding: 0 0 5% 0 !important;
      }
    }
  }
  .dashboard-box-row-wrapper {
    &.after {
      padding-right: 0.75%;
    }
    &.before {
      padding-left: 0.75%;
    }
    @media only screen and (max-width: 736px) {
      padding: unset !important;
    }
  }
}

.community-rtl {
  .dashboard-container {
    .dashboard-actions-wrapper {
      .card-container:nth-of-type(2) {
        padding: 0 1.5% 0 0.75%;
      }

      .card-container:nth-of-type(3) {
        padding: 0 0.75% 0 1.5%;
      }
    }
    .dashboard-box-row-wrapper {
      &.after {
        padding-right: unset;
        padding-left: 0.75%;
      }
      &.before {
        padding-left: unset;
        padding-right: 0.75%;
      }
    }
  }
}
