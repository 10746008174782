@import "src/assets/scss/variables";

.my-messages-page {
  .notifications-count {
    margin: 0 25px;
  }
  .section {
    margin-bottom: 25px;
    .item-row {
      display: flex;
      align-items: center;
      padding: 15px;
      border-bottom: 1px solid $grey;
      &:last-child {
        border-bottom: none;
      }
      .avatar {
        margin-right: 25px;
        width: 50px;
        height: 50px;
      }
      .new-item {
        margin-left: -15px;
        margin-right: 15px;
      }
    }
  }
}
