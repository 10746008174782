.departments-page {
  .group {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 62px;
    .label {
      top: 0;
      position: absolute;
      font-size: 12px;
    }
    .main-wrapper-departments {
      min-height: 535px;
    }
  }
}

.main-wrapper-departments {
  min-height: 535px;
}

.segmentation-title {
  font-size: large;
}
