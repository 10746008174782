.current-week-events-container {
    padding: 4.7%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    .current-week-events-title {
        margin-bottom: 30px;
        font-size: 18px;
    }
    .scroll-container {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
    }
}
