@import '../../../assets/scss/variables';

.surveys-page {
    .page-heading {
        margin-top: 0;
    }
    .table-grid {
        .MuiDataGrid-cell {
            cursor: pointer;
        }
    }
}
