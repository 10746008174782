@import "../../../../../assets/scss/variables";
.successful-notification-container {
    .back-icon-container {
        padding: 16px 16px 18px;
    }
    .image-holder {
        position: relative;
        text-align: center;
        img {
            width: 165px;
            height: 275px;
        }
        div {
            position: absolute;
            left: 41%;
            top: 12px;
            color: $medium-blue;
            span {
                display: block;
            }
            .top {
                font-size: 10px;
            }
            .bottom {
                font-size: 16px;
                font-weight: 900;
                line-height: 1;
            }
        }
    }
    p {
        margin: 5px;
    }
    .great {
        font-size: 20px;
        color: $light-blue;
    }
    .message {
        font-size: 14px;
        margin-bottom: 55px;
        p {
            width: 60%;
            text-align: center;
        }
    }
}

.community-rtl {
    .successful-notification-container { 
        .icon {
            transform: rotateZ(180deg);
        }
        .image-holder {
            div {
                left: 44%;
            }
        }
    }
  }