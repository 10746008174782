@import '../../../assets/scss/variables';

.messages-page {
    .page-heading {
        margin: 13px 16px;
        font-size: 20px;
        font-weight: normal;
        .notifications-count {
            margin: 0;
            width: 20px;
        }
    }
    .section-heading {
        margin: 8px 16px;
        font-size: 14px;
        font-weight: normal;
    }
    .item-container {
        background-color: $white;
        border: 1px solid $border-gray;
        .item-row {
            display: flex;
            padding: 12px 0;
            margin: 0 15.5px;
            border-bottom: 1px solid $border-gray;
            &:last-child {
                border-bottom: none;
            }
            .MuiAvatar-root {
                margin-left: 12px;
            }
            .item-message-container {
                .item-message {
                    margin-top: 0;
                }
                &.justify-center {
                    .item-message {
                        margin: 0;
                    }
                }
                button {
                    width: 140px;
                }
            }
        }
    }
}

.community-rtl {
    .messages-page {
        .notifications-count {
            .MuiBadge-badge {
                right: 20px;
            }
        }
    }
}