@import "../../../../assets/scss/variables";

.draft-item-container {
    position: relative;
    .draft-item-content-wrapper {
        cursor: pointer;
        opacity: 0;
        z-index: 5;
        display: flex;
        position: absolute;
        width: 100%;
        .btn-with-icon-wrapper {
            position: relative;
            img {
                padding-top: 3px;
                transition: 0.3s ease all;
            }
        }

        &:hover {
            .btn-with-icon-wrapper {
                img {
                    transform: translateX(6px);
                }
            }

            .image-container {
                transform: scale(1.1);
                img {
                }
            }
        }
    }
    img {
        height: 60px;
        width: 60px;
    }
    .btn-with-icon-wrapper {
        height: 31px;
        width: 37px;
        margin-left: 48px;
        img {
            height: 20px;
            width: 20px;
            transition: padding 200ms ease-in-out;
        }
    }
    .draft-into-wrapper {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        margin-left: 19px;
        width: 46%;
        .tag-text {
            color: $dark-gray;
            margin-bottom: 5px;
        }
        .short-info-text {
            width: 100%;
            line-height: 20px;
        }
        @media only screen and (max-width : 768px) { 
            max-width: 40%;
            margin-left: 10px;
            font-size: 11px;
        }
    }
    .btn-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .hide {
        transition: opacity 500ms ease-in-out;
        opacity: 0;
    }
    .fade-in {
        transition: opacity 500ms ease-in-out;
        opacity: 1;
    }
    .show {
        transition: all 0.2s ease-in-out;
        opacity: 1;
    }
    .draft-item-loader-wrapper {
        cursor: default;
        position: absolute;
        z-index: 4;
    }
    .draft-image {
        border-radius: 5px;
        background-image: none !important;
        background-color: $black;
    }
}
