@import "../../../assets/scss/variables";

.settings-community {
  .item-box {
    .action-box {
      border-top: 1px solid $dark-gray;
    }
    &:hover {
      .action-box {
        border-color: $light-blue;
      }
    }
  }
  .preview-container {
    position: relative;
    box-sizing: border-box;
    width: 280px;
    @media only screen and (max-width: 768px) {
      width: 210px;
    }
  }

  .background-container {
    position: relative;
    width: 280px;
    height: 280px;
    @media only screen and (max-width: 768px) {
      width: 210px;
      height: 210px;
    }
    .crop-wrapper {
      position: absolute;
      width: 280px;
      height: 280px;
      @media only screen and (max-width: 768px) {
        width: 210px;
        height: 210px;
      }
    }
  }

  .background {
    width: 280px;
    height: 280px;
    @media only screen and (max-width: 768px) {
      width: 210px;
      height: 210px;
    }
  }

  .proportion-container {
    position: absolute;
    top: -16px;
    font-weight: bold;
    font-size: 13px;
    @media only screen and (max-width: 1024px) {
      font-size: 10px;
    }
  }

  .logo-wrapper {
    position: absolute;
    width: 50%;
    height: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    width: 140px;
    height: 140px;
    @media only screen and (max-width: 768px) {
      width: 105px;
      height: 105px;
    }
    &.round {
      border-radius: 50%;
    }
    .crop-wrapper {
      position: absolute;
      width: 140px;
      height: 140px;
      @media only screen and (max-width: 768px) {
        width: 105px;
        height: 105px;
      }
    }
  }

  .logo {
    max-width: 140px;
  }

  .content-container {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .content-links {
    bottom: 0;
    display: flex;
    align-items: center;
    filter: $filter-white;
    cursor: grab;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
    &:active {
      cursor: grabbing;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .link {
      padding: 5px;
      margin: 0 15px;
      .link-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .information-container {
    max-height: 300px;
    overflow-y: auto;
    padding: 16px 16px 32px;
    background: lightgray;
    .community-leader-container {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .user-photo {
      width: 50px;
      height: 50px;
      @media only screen and (max-width: 768px) {
        width: 37.5px;
        height: 37.5px;
      }
    }
    .user-name {
      font-size: 1.125em;
    }
    .user-info {
      margin: 3px 0;
      font-size: 0.875em;
    }
  }

  .content-separator {
    border-bottom: 1px solid $white;
  }

  .user-list {
    height: 380px;
    overflow-y: auto;
    .search-field-container {
      margin: 0 15px;
      width: 58%;
      svg {
        width: 24px;
        filter: saturate(0%) brightness(0.5);
      }
    }
    .role-description {
      margin: 5px 50px;
      width: 58%;
      border-bottom: 1px solid $dark-gray;
    }
  }
}
