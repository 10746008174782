@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/common";

.create-mobile-article {
  height: 100%;
  .back-icon {
    width: 25px;
    height: 25px;
    transform: rotate(-180deg);
  }
  h2,h3 {
    font-weight: normal;
  }
  h3 {
    font-size: 16px;
  }
  .heading-container {
    padding: 16px 16px 18px;
    .page-heading {
      margin-bottom: 4px;
    }
  }
  
  .multi-select {
    .dropdown-container {
      border-radius: 0;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-color: $dark-gray;

      &:focus-within {
        box-shadow: none;
      }

      .dropdown-heading {
        padding: 0;
      }
    }

    &.disabled {
      .dropdown-container {
        border-color: $dark-gray;
        border-style: dotted;

        &:focus-within {
          border-color: $dark-gray;
        }

        .dropdown-heading-value {
          color: $dark-gray;
        }
      }
    }
  }

  .new-article {
    display: flex;
    .article-type-scroll {
      width: 100%;
      height: 250px;
      overflow-x: auto;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
      .article-type-container {
        width: 200%;
        .article-type {
          width: 139px;
          height: 250px;
          display: inline-block;
          margin: 0 10px;
          &:first-child {
            margin-left: 16px;
          }
          cursor: pointer;
          img {
            width: 100%;
            height: auto;
          }
          p {
            margin: 0;
          }
          &:hover,
          &.active {
            p {
              color: $light-blue;
            }
          }
        }
      }
    }
  }

  .create-preview {
    .main-wrapper {
      padding: 16.5px;
      box-sizing: border-box;
      border: 1px solid $border-gray;
      background: $white;
      &:first-child {
        margin-bottom: 11.5px;
      }
    }
    .create-grid {
        .grid-row {
          margin: 6px 0;
          & > p {
            font-size: 15px;
          }
          .MuiFormControl-marginNormal {
            margin-top: 0;
          }
        }
      .preview-container {
        position: relative;
        height: fit-content;
        .preview-image-wrap {
          width: 100%;
          box-shadow: 0px 0px 50px rgba(16, 13, 13, 0.4);
          margin-top: 20px;
          overflow: hidden;
          .preview-image {
            position: relative;
            height: 100%;
            background-size: 100%;
            &.template-2 {
              height: 43%;
            }
            &.template-3 {
              background-color: $white;
            }
          }
          .preview-icons-mobile {
            display: flex;
            position: absolute;
            left: 7%;
            bottom: 6.4%;
            z-index: 6;
            .icon {
              margin: 0 5px;
              width: 15px;
              height: 15px;
              filter: $filter-white;
              &.favorite:hover {
                filter: $filter-red;
              }
              &.dots:hover {
                filter: $filter-dark-gray;
              }
            }
            &.template-2 {
              .icon {
                filter: $filter-black;
              }
            }
          }
          .preview-dates {
            display: block;
            position: absolute;
            right: 7%;
            bottom: 7.2%;
            font-size: 0.82em;
          }
        }
        .preview-category {
          background: $cyan;
          width: fit-content;
          height: fit-content;
          padding: 5px 20px;
          font-size: 0.8em;
          max-width: 85%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .template-1-container {
          position: absolute;
          bottom: 5%;
          left: 8.5%;
          text-align: right;
          width: 84%;
          p {
            color: $white;
          }
          .preview-header {
            font-size: 1.05em;
            overflow-wrap: break-word;
            word-wrap: break-word;
            hyphens: auto;
            text-shadow: 1px 1px 2px #000;
            font-weight: 600;
            &.sub-header {
              font-size: 0.9em;
              max-height: 32px;
              overflow: hidden;
            }
          }
        }
        .preview-date {
          font-size: 0.8em;
        }
      }
      .template-2-container {
        background: $dashboard-background;
        width: 100%;
        height: 100%;
        padding: 5% 10% 10%;
        margin-top: 0;
        box-sizing: border-box;
        &::-webkit-scrollbar {
          display: none;
        }
        .preview-short-text {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 78%;
          overflow: hidden;
          &-header {
            font-weight: bold;
          }
          .preview-text-header {
            font-weight: 600;
          }
        }
      }
      .template-3-container {
        position: absolute;
        top: 5%;
        right: 8%;
        width: 84%;
        height: 90%;
        .preview-short-text {
          max-height: 72%;
          overflow: hidden;
        }
        .preview-text-header {
          font-size: 1.5rem;
          font-weight: 600;
          margin-bottom: 10%;
        }
        .preview-date {
          margin: 10% 0 4%;
          font-size: 0.8rem;
        }
      }
      .preview-text-container {
        background: $dashboard-background;
        width: 100%;
        height: 57%;
        padding: 5% 10% 10%;
        margin-top: 0;
        box-sizing: border-box;
        &::-webkit-scrollbar {
          display: none;
        }
        .preview-short-text {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          &-header {
            font-weight: bold;
          }
          .preview-text-header {
            font-weight: 600;
          }
        }
      }
      .tox-toolbar::-webkit-scrollbar {
        display: none;
      }
      .tox-edit-area {
        overflow: hidden !important;
      }
    }
  }
  .preview-and-publish {
    height: 100%;
    position: relative;
    .image-container {
      width: 100%;
      .article-image {
        width: 100%;
        height: inherit;
        border-radius: 0;
        object-fit: cover;
        &.template-3 {
          background-image: none !important;
          background-color: $white;
        }
      }
    }
    .text-container {
      padding: 16px;
      .category {
        background: $cyan;
        width: fit-content;
        height: fit-content;
        padding: 5px 10px;
        font-size: 11px;
        max-width: 85%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 8px;
      }
      .header {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 8px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
      }
      .writer-and-date {
        margin-bottom: 8px;
        font-size: 13px;
        .line {
          margin: 0 7px;
        }
      }
      .content {
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
      }
    }
    .phone-icons {
      width: 100%;
      background: $black;
      position: absolute;
      bottom: 0;
      .icon-container {
        margin: 0 16px;
        &.back-icon {
          transform: rotateZ(180deg);
        }
        .share-icon {
          .icon {
            width: 12px;
            margin: 3px 6px 2px;
            img {
              max-width: 100%;
            }
          }
        }
        .icon {
          font-size: 18px;
          filter: $filter-white;
          &.favorite {
            margin: 3px 6px 0;
          }
        }
      }
    }
  }
  .submit-buttons {
    position: fixed;
    bottom: 56px;
    padding: 8px 16px;
    background: $white;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid $border-gray;
    z-index: 7;
    button {
      width: 50%;
      height: 40px;
      margin: 4px;
      padding: 0;
    }
  }
}

.community-rtl {
  .create-article.article {
    .create-preview {
      .create-grid {
        .preview-container {
          .preview-image-wrap {
            right: unset;
            left: 6%;
          }
        }
      }
    }
  }
}
