@import "../../../assets/scss/variables";

.create-benefit {
    .benefit-wrapper {
        padding: 30px 75px 20px;
    }
    .create-options {
        margin-bottom: 50px;
    }

    .small-separator {
        width: 27px;
    }
    .separator {
        width: 76px;
    }

    .show-original-image-button {
        margin-bottom: 10px;
        button {
            font-size: 1em;
        }
    }

    .proportion-container {
        position: fixed;
        font-weight: bold;
        color: $black;
        z-index: 10;
        margin: 5px 0;
        @media only screen and (max-width: 1024px) {
            font-size: 10px;
        }
    }

    .create-preview {
        .create-grid {
            .container-box {
                border: 1px solid $dark-gray;
                border-radius: 5px;
                padding: 15px;
                margin: 0 7.5px 20px;
                .container-box-item {
                    margin-bottom: 5px;
                }
                &:hover {
                    border-color: $light-blue;
                    color: $light-blue;
                }
                .add-option-button {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    margin: 5px;
                    & > * {
                        margin: 0 2px;
                    }
                    &:hover {
                        filter: $filter-light-blue;
                    }
                }
            }
            .grid-row {
                padding: 15px 0;
                input[type="number"] {
                    -moz-appearance: textfield;
                }
                .text-area {
                    width: 100%;
                    padding: 10px;
                    resize: none;
                }
                &.box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    height: 93px;
                    margin: 0 7.5px;
                    border: 1px solid $dark-gray;
                    border-radius: 5px;
                    font-size: 13px;
                    cursor: pointer;
                    &:hover,
                    &.active {
                        border-color: $light-blue;
                        color: $light-blue;
                    }
                    &.disabled {
                        cursor: unset;
                        &:hover,
                        &.active {
                            border-color: $dark-gray;
                            color: unset;
                        }
                    }
                }
            }
            .preview-container {
                position: relative;
                height: fit-content;
            }
            .fixed {
                position: fixed;
                top: 35%;
                right: 9.5%;
                box-shadow: 0px 0px 60px rgba(16, 13, 13, 0.4);
                width: 403px;
                @media only screen and (max-width: 1440px) {
                    width: 322.4px;
                    right: 7.5%;
                }
                @media only screen and (max-width: 1024px) {
                    width: 268.75px;
                    right: 10.5%;
                }
                @media only screen and (max-width: 768px) {
                    width: 201.6px;
                    right: 7%;
                }
                .preview-image {
                    width: inherit;
                    height: 268.6px;
                    position: relative;
                    background-size: cover;
                    background-position: center;
                    @media only screen and (max-width: 1440px) {
                        height: 214.9px;
                    }
                    @media only screen and (max-width: 1024px) {
                        height: 179.1px;
                    }
                    @media only screen and (max-width: 768px) {
                        height: 134.4px;
                    }
                    img {
                        height: auto;
                        width: 100%;
                        &.no-crop {
                            height: 100%;
                        }
                    }
                }
                .preview-text-container {
                    word-break: break-word;
                    overflow-wrap: break-word;
                    width: inherit;
                    background: $dashboard-background;
                    max-height: 208px;
                    padding: 4% 5% 6%;
                    box-sizing: border-box;
                    @media only screen and (max-width: 1440px) {
                        max-height: 166.4px;
                    }
                    @media only screen and (max-width: 1024px) {
                        max-height: 138.75px;
                    }
                    @media only screen and (max-width: 768px) {
                        max-height: 104px;
                    }
                    .preview-text {
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                        &-header {
                            font-weight: bold;
                        }
                        @media only screen and (max-width: 768px) {
                            .date-text {
                                font-size: 9px;
                            }
                        }
                        .preview-text-header {
                            margin: 10px 0;
                            font-size: 1.2em;
                        }
                        .time-icon {
                            width: 20px;
                            height: 20px;
                        }
                        .store-date-time {
                            margin: 0 2px;
                            .separator {
                                text-align: center;
                                width: 10px;
                            }
                        }
                        .store-price {
                            margin: 2px;
                            font-size: 1.125rem;
                            font-weight: bold;
                        }
                    }
                }
            }
            .date-picker-inline,
            .number_of_tickets,
            .price-field {
                width: 80%;
            }
            .form-control-label {
                color: $black;
                &:hover {
                    color: $black;
                }
            }
        }
    }

    .preview-container {
        position: relative;
        flex-basis: 352px;
        .preview-image-container {
            position: relative;
            width: 100%;
            height: inherit;
            display: flex;
            justify-content: center;
            flex-direction: column;
            .preview-image {
                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
            .preview-text-container {
                background: $border-gray;
                max-height: 31%;
                padding: 3% 4%;
                box-sizing: border-box;
                word-break: break-word;
                overflow-wrap: break-word;
                .preview-text {
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    font-size: 0.56em;
                    &-header {
                        font-weight: bold;
                    }
                    .preview-text-header {
                        margin: 3% 0;
                        font-size: 1.2em;
                    }
                    .preview-description {
                        font-size: 1em;
                    }
                    .date-text {
                        font-size: 0.75em;
                    }
                    .location-icon {
                        .MuiSvgIcon-root {
                            width: 0.75em;
                            height: 0.75em;
                        }
                    }
                    .time-icon {
                        width: 0.75em;
                        height: 0.75em;
                    }
                    .store-date-time {
                        margin: 0 2px;
                        .separator {
                            text-align: center;
                            width: 10px;
                        }
                    }
                    .store-price {
                        margin: 2px;
                        font-size: 0.75rem;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .crop-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .dates {
        margin: 3% 0;
    }
}

.tox-notifications-container,
.tox-statusbar__text-container {
    display: none !important;
}

.MuiPickersTimePickerToolbar-ampmSelection {
    margin: 0 20px !important;
}

.community-rtl .MuiPickersTimePickerToolbar-hourMinuteLabel {
    flex-direction: row-reverse !important;
}
.community-rtl .MuiPickersToolbar-toolbar {
    flex-direction: row-reverse !important;
}

.community-rtl {
    .create-benefit {
        .create-preview {
            .create-grid {
                .preview-container {
                    .fixed {
                        right: unset;
                        left: 9.5%;
                        @media only screen and (max-width: 1440px) {
                            right: unset;
                            left: 6.5%;
                        }
                        @media only screen and (max-width: 1024px) {
                            left: 7.5%;
                        }
                    }
                }
            }
        }
    }
}
