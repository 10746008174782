@import "../../assets/scss/variables";

.modal-preview.notification-modal {
    .notification-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 300px;
        height: 180px;
        background: $white;
        margin: 35vh auto 0;
        .message-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 80%;
        }
        p {
            margin: 5px 0 15px;
            text-align: center;
        }
        .close-container {
            position: absolute;
            top: 20px;
            right: 20px;
            .icon-close {
                cursor: pointer;
                font-size: 1.5rem;
            }
        }
        .submit-button {
            display: flex;
            justify-content: space-evenly;
            button {
                margin: 0 5px;
            }
        }
        @media only screen and (max-width: 767px) {
            width: 250px;
            .message-container {
                font-size: 12px;
            }
        }
    }
}
