@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/common";

.create-article.ad {
  &.list-preview {
    .preview-image-container {
      height: auto;
    }
  }
  .create-preview {
    .create-grid {
      .grid-row {
        padding: 15px 0;
        .text-area {
          width: 100%;
          padding: 10px;
          resize: none;
        }
      }
      .preview-container {
        position: relative;
        height: fit-content;
        height: 376px;
        padding: 0;
        width: 352px;
        display: flex;
        align-items: flex-end;
        background-size: cover;
        background-position: center;
        @media only screen and (max-width: 1440px) {
          height: 300.8px;
          width: 281.6px;
        }
        @media only screen and (max-width : 1024px) {
          width:  220px;
          height: 235px;
        }
        .fixed {
          position: fixed;
          top: 25%;
          right: 9.5%;
          box-shadow: 0px 0px 50px rgba(16, 13, 13, 0.4);
          z-index: 5;
          width: inherit;
          height: inherit;
        }
        .preview-image {
          position: relative;
          height: inherit;
          padding: 0;
          width: inherit;
          display: flex;
          align-items: flex-end;
          background-size: cover;
          background-position: center;
          .crop-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
        .preview-text {
          text-align: left;
          // width: 84%;
          position: relative;
          padding: 4.2% 8.8% 8.8%;
          width: inherit;
          background-color: $black;
          box-sizing: border-box;
          box-shadow: 0px 10px 50px rgba(16, 13, 13, 0.4);
          .preview-category {
            background: $cyan;
            width: fit-content;
            height: fit-content;
            padding: 5px 20px;
            font-size: 0.8em;
            max-width: 85%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          p {
            color: $white;
          }
          .preview-header {
            font-size: 1.05em;
            overflow-wrap: break-word;
            word-wrap: break-word;
            hyphens: auto;
            text-shadow: 1px 1px 2px #000;
            &.sub-header {
              font-size: 0.9em;
              max-height: 32px;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  .preview-image-container {
    position: relative;
    box-shadow: 0px 0px 20px rgba(16, 13, 13, 0.4);
    width: 100%;
    height: 68%;
    .preview-image {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .preview-text {
      position: relative;
      bottom: 5px;
      text-align: left;
      padding: 1vw 1.55vw 1.55vw;
      width: inherit;
      background-color: $black;
      box-sizing: border-box;
      .preview-category {
        background: $cyan;
        width: fit-content;
        height: fit-content;
        padding: 2% 8%;
        font-size: 0.6rem;
        max-width: 85%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      p {
        color: $white;
        margin: 5% 0;
      }
      .preview-header {
        font-size: 0.6rem;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        text-shadow: 1px 1px 2px #000;
        &.sub-header {
          font-size: 0.5rem;
          max-height: 18px;
          overflow: hidden;
        }
      }
    }
  }
}

.community-rtl {
  .create-article.ad {
    .create-preview {
      .create-grid {
        .preview-container {
          .fixed {
            right: unset;
            left: 9.5%;
          }
        }
      }
    }
  }
}
