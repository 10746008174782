@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/common";

.notification-page {
  .subtitle {
    margin-bottom: 10px;
  }
  .page-header {
    font-size: 1.89rem;
  }
  .preview-image-wrap {
    width: 100%;
    box-shadow: 0px 0px 50px rgba(16, 13, 13, 0.4);
    overflow: hidden;
    .preview-image {
      position: relative;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

.preview-notification-container {
  border: 1px solid $border-gray;
  border-radius: 15px;
  .preview-header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: $message-header;
    .preview-headline {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .preview-image {
        width: 20px;
        height: 20px;
        img {
          border-radius: 5px;
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
    .preview-when {
      margin: 0 5px;
    }
  }
  .preview-footer {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: $dashboard-background;
    padding: 10px 20px 25px;
  }
}

.MuiPickersTimePickerToolbar-ampmSelection {
  margin: 0 20px !important;
}

.community-rtl {
  .MuiPickersTimePickerToolbar-hourMinuteLabel {
    flex-direction: row-reverse !important;
  }
  .MuiPickersToolbar-toolbar {
    flex-direction: row-reverse !important;
  }
  .preview-message-text {
    margin-right: 10px;
  }
}