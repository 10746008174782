@import "../../assets/scss/variables";

.number-field {
    position: relative;
    .input {
        .MuiInput-input {
            text-align: center;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        input[type=number] {
            -moz-appearance:textfield;
        }
    }
    .change-value-icon {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
        position: absolute;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: $border-lightGray;
        top: 27px;
        cursor: pointer;
        &:active:hover {
            background-color: $border-gray;
        }
        &:hover {
            div {
                color: $light-blue;
            }
        }
        &.increase {
            left: 5px;
            div {
                position: absolute;
                top: -3px;
                left: 3px;
                @media only screen and (max-width : 1024px) {
                    top: -2px;
                }
                @media only screen and (max-width : 768px) {
                    top: 0;
                    left: 3px;
                }
            }
        }
        &.decrease {
            right: 5px;
            div {
                position: absolute;
                top: -5px;
                left: 4px;
                @media only screen and (max-width : 1024px) {
                    top: -1px;
                }
                @media only screen and (max-width : 768px) {
                    left: 5px;
                }
            }
        }
    }
}
