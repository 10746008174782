.pie-chart-container {
    display: flex;
    .fade-effect-wrapper {
        position: relative;
        display: flex;
        opacity: 0;
        z-index: 5;
        @media only screen and (max-width : 768px) {
            flex-direction: column;
        }
    }
    .chart-item {
        position: relative;
        z-index: 8;
        opacity: 0;
        @media only screen and (max-width : 768px) {
            display: flex;
            justify-content: center;
        }
    }
    .loader-wrapper {
        z-index: 2;
        transition: all linear 500ms;
    }
    .leave {
        opacity: 0;
    }
    .visible {
        transition: opacity 500ms ease-in-out;
        opacity: 1;
    }
}
