@import "../../../assets/scss/variables";
@import "../../../assets/scss/common";

.dashboard-search {
  .field-label {
    font-size: 16px;
  }
  .multi-select {
    .dropdown-container {
      border-radius: 0;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-color: $dark-gray;
      font-size: 16px;
      &:focus-within {
        box-shadow: none;
      }
      .dropdown-heading {
        padding: 0;
      }
    }
    &.disabled {
      .dropdown-container {
        border-color: $dark-gray;
        border-style: dotted;
        &:focus-within {
          border-color: $dark-gray;
        }
        .dropdown-heading-value {
          color: $dark-gray;
        }
      }
    }
  }
  input[type="checkbox"],
  input[type="radio"],
  .PrivateSwitchBase {
    position: relative !important;
  }
}
