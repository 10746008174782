@import '../../../assets/scss/variables';

.create-community {
    .main-wrapper {
        background: $white;
        h3 {
            font-size: 1em;
            font-weight: normal;
        }
        .grid-row {
            margin-bottom: 24px;
        }
        .item-box {
            border: 1px solid $dark-gray;
            border-radius: 5px;
            padding: 20px 0;
            margin-bottom: 22px;
            &:active, &:hover {
                border-color: $light-blue;
            }
            .action-box {
                border-top: 1px solid $dark-gray;
            }
            &:hover {
                .action-box {
                    border-color: $light-blue;
                }
            }
            .action-box-item {
                margin-top: 15px;
            }
        }
    }
    .submit-button {
        margin: 50px 0;
    }
}