@import "../../../assets/scss/variables";

.modal-preview.unsaved-changes {
    .notification-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 420px;
        height: 277px;
        background: $white;
        margin: 15% auto 0;
        .message-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            margin-bottom: 20px;
            width: 70%;
            .message {
                font-size: 1.125rem;
            }
            .sub-message {
                font-size: 0.813rem;
            }
        }
        .button-container {
            display: flex;
            justify-content: space-between;
            .action-button {
                max-height: 38px;
                font-size: 16px;
                margin: 0 5px;
                padding: 6px 20px;
            }
        }
        .close-container {
            position: absolute;
            top: 20px;
            right: 20px;
            .icon-close {
                cursor: pointer;
                font-size: 1.5rem;
            }
        }
    }
}
