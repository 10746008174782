@import "../../../assets/scss/variables";

.article-box {
    background: $white;
    padding: 16.5px;
    box-sizing: border-box;
    border: 1px solid $border-gray;
    margin: 6px 0;

    .wrap-box {
        justify-content: space-between;
    }
    .article-item {
        margin: 4px 0;
        &.image {
            margin: 8px 0;
            width: 50%;
            height: 60px;
        }
        &.button {
            button {
                width: 95%;
                font-size: 14px;
            }
        }
        .article-action {
            cursor: pointer;
            .article-action-icon {
                height: 26px;
                width: 26px;
                transform: rotateZ(90deg);
            }
            &:hover {
                .article-action-icon {
                  fill: $light-blue;
                  transform: scale(1.1) rotateZ(90deg);
                }
            }
            &.lock {
                margin: 0 12px;
            }
        }
        .article-image {
            height: 60px;
            width: 60px;
            border-radius: 5px;
            object-fit: cover;
            &.article-template-3 {
                background-image: none !important;
                background-color: $dark-gray;
            }
        }
        .article-text {
            font-size: 14px;
            overflow-wrap: anywhere;
            word-wrap: anywhere;
            white-space: normal;
            line-height: 1;
            &.header {
                color: $dark-gray;
            }
        }
    }
}
