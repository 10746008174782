@import "../../../assets/scss/variables";

.main-content .invite-to-app {
  .page-wrap {
    padding-top: 130px;
  }

  .bolder-font {
    font-weight: bold;
  }

  .padding-top-20 {
    padding-top: 20px;
  }

  .card-wrapper {
    position: relative;
    overflow: visible;
    padding: 5%;
    .card-content {
      padding: 0;
    }

    .title-image {
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      width: 125px;
      height: 125px;
      top: -75px;
      margin: 0 auto;
      left: 0;
      right: 0;
      @media only screen and (max-width: 768px) {
        width: 100px;
        height: 100px;
        top: -50px
      }
    }

    .title {
      text-align: center;
      padding: 25px 0;
    }

    .singleline {
      label {
        transform: translate(0px, -15px);
        color: $light-blue;
      }
    }
    .multiline {
      label {
        transform: translate(0px, -15px);
        color: $light-blue;
      }
      legend {
        display: none;
      }
    }

    .action-button {
      margin-top: 40px;
    }
  }
  .form-item {
    margin-bottom: 25px;
  }
  @media only screen and (max-width : 1024px) {
    .email-title {
      input::placeholder {
        font-size: 0.8rem;
      }
    }

    .MuiGrid-spacing-xs-4 {
      width: calc(100% + 42px);
      & > .MuiGrid-item {
        padding: 12px;
      }
    }
  }
}
