@import "../../../../assets/scss/variables";

.mobile-side-menu {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    opacity: 0;
    z-index: -1;
    transition: all 0.5s ease-out;
    .content-container {
        position: absolute;
        width: 67%;
        left: -100%;
        height: 100%;
        background: $white;
        transition: all 0.5s ease-out;
        .close-container {
            position: absolute;
            top: 10px;
            right: -35px;
            .icon-close {
                cursor: pointer;
                font-size: 26px;
                filter: $filter-white;
            }
        }
        .user-container {
            height: 21.5%;
            .avatar-icon {
                margin-bottom: 12px;
                .MuiAvatar-root {
                    width: 60px;
                    height: 60px;
                }
            }
        }
        .action-container {
            background: $light-gray;
            height: 78.5%;
            .action {
                padding: 14px 0;
                margin-left: 16px;
                margin-right: 16px;
                border-bottom: 1px solid $grey;
                font-size: 14px;
                &.no-border {
                    border: 0;
                }
                &-text {
                    margin: 0 10px;
                }
                &-icon {
                    .icon {
                        width: 20px;
                    }
                }
            }
            .create-actions {
                .action {
                    color: $light-blue;
                }
            }
        }
    }
    &.opened {
        opacity: 1;
        z-index: 100;
        .content-container {
            left: 0;
        }
    }

    .role-title {
        color: $dark-gray;
    }
}

.community-rtl {
    .mobile-side-menu {
        .content-container {
            left: unset;
            right: -100%;
            .close-container {
                right: unset;
                left: -35px;
            }
        }
        &.opened {
            .content-container {
                left: unset;
                right: 0;
            }
        }
    }
}