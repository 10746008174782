@import "../../assets/scss/variables";

.search-panel-container {
    width: 75%;
    .search-panel-header {
        margin: 25px 0;
    }
    .MuiGrid-item {
        margin-bottom: 15px;
        &.search-date-first {
            margin-bottom: 0;
        }
    }
    .search-panel-title {
        font-size: 18px;
    }
    .search-panel-close {
        cursor: pointer;
    }
    .search-panel-subTitle {
        margin-top: 25px;
        font-size: 14px;
    }
    .search-field-container {
        svg {
            width: 24px;
            filter: saturate(0%) brightness(0.5);
        }
    }
    .search-button-container {
        margin: 10px 0;
        button {
            width: 100%;
            font-size: 0.6em;
        }
    }
    .search-panel-button-group {
        button {
            font-size: 14px;
            text-transform: none;
        }
    }
    .checkbox-item {
        margin-bottom: 0;
    }
}