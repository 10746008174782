@import "../../../../assets/scss/variables";

.lagent-list-wrapper {
    width: 100%;
    margin-left: 7%;
    .legent-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 15px;
        .precent-text {
            padding-top: 4px;
            font-size: 1.1rem;
            font-family: $community-font;
            height: fit-content;
            @media only screen and (max-width : 768px) {
                font-size: 0.9rem;
            }
        }
        .legent-label {
            line-height: 20px;
            padding-left: 10%;
            font-size: 0.875rem;
            max-width: 228px;
        }
    }
}

.community-rtl .precent-text {
    margin: 0 15px;
}