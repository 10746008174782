@import "../../assets/scss/variables";

.settings-page {
  span {
    margin-right: 3px;
  }
  .page-heading {
    font-weight: bold;
    margin-bottom: 72px;
  }
  .content-grid {
    margin-top: 36px;
  }
  .content-header {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
  }
  .help-icon {
    margin: 0 10px;
    &.upload {
      margin: 0 5px;
      filter: $filter-dark-gray;
    }
  }
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }
  .content-grid {
    .grid-row {
      margin-bottom: 24px;
    }
    .background-color {
      &-box {
        width: 50px;
        height: 50px;
        border: 1px solid $dark-gray;
        border-radius: 3px;
      }
    }
    .phone-container {
      position: relative;
      width: 305px;
      &-header {
        width: 150px;
        text-align: center;
        position: absolute;
        top: 50px;
        left: 80px;
        color: $white;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &-top-nav {
        position: absolute;
        top: 19px;
        left: 23px;
      }
      &-bottom-nav {
        position: absolute;
        bottom: 28px;
        left: 100px;
      }
      &-logo {
        position: absolute;
        bottom: 31px;
        left: 142px;
        width: 28px;
        height: 28px;
      }
      @media only screen and (max-width: 768px) {
        width: 228.75px !important;
        &-template {
          width: 100%;
          height: auto;
        }
        &-header {
          width: 112.5px !important;
          top: 38px !important;
          left: 62px !important;
        }
        &-top-nav {
          width: 195px;
          height: auto;
          top: 15px !important;
          left: 17px !important;
        }
        &-bottom-nav {
          width: 125px;
          height: auto;
          bottom: 19px !important;
          left: 74px !important;
        }
        &-logo {
          bottom: 21px !important;
          left: 104px !important;
          width: 21px !important;
          height: 21px !important;
        }
      }
    }
    .column-headers {
      border-bottom: 1px solid $dark-gray;
      padding-bottom: 18px;
      margin-bottom: 22px;
      .column-name {
        color: $dark-gray;
        font-size: 12px;
      }
    }
    .select-all {
      margin-bottom: 22px;
      &-checkbox {
        padding: 0;
      }
      &-text {
        margin-left: 9px;
        margin-right: 9px;
      }
      .double-icon {
        margin: 0 8px;
        & > * {
          margin: 0 12px;
        }
      }
    }
    .item-box {
      border: 1px solid $dark-gray;
      border-radius: 5px;
      padding: 20px 0;
      margin-bottom: 22px;
      &:active,
      &:hover {
        border-color: $light-blue;
      }
    }
    .MuiTextField-root {
      margin-bottom: 15px;
    }
    .item-margin {
      margin: 0 3.2%;
    }
    .add-item-box {
      display: flex;
      align-items: center;
      font-size: 15px;
    }
  }
  .categories-page {
    .select-filter {
      margin-bottom: 15px;
    }
    .benefit-phone {
      width: 241px;
      height: 487px;
      @media only screen and (max-width: 768px) {
        width: 180.75px;
        height: 365.25px;
      }
    }
    .content-preview-container {
      position: relative;
      .content-container {
        display: flex;
        align-items: center;
        position: absolute;
        top: 317px;
        left: 17px;
        right: 17px;
        width: 205px;
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        .category {
          padding: 5px 5px 3px;
          border-bottom: 2px solid transparent;
          cursor: pointer;
          white-space: nowrap;
          font-size: 14px;
          &.active {
            border-bottom-color: $light-blue;
          }
        }
        @media only screen and (max-width: 768px) {
          width: 153.75px;
          top: 235px;
          left: 13px;
          right: 13px;
          .category {
            font-size: 12px;
          }
        }
      }
      .displayed-content {
        position: absolute;
        top: 360px;
        font-size: 20px;
        left: 30px;
        right: 30px;
        @media only screen and (max-width: 768px) {
          top: 270px;
          font-size: 18px;
          left: 20px;
          right: 20px;
        }
      }
    }
    .benefits-preview-container {
      position: relative;
      .benefits-preview {
        position: absolute;
        display: flex;
        justify-content: center;
        top: 58px;
        left: 21px;
        width: 205px;
        height: 379px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        .category-container {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          width: 92%;
          margin-top: 13px;
        }
        .category {
          position: relative;
          width: 86px;
          height: 86px;
          margin: 3px;
          img,
          .no-image {
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
          .no-image {
            background-color: $black;
          }
          div {
            color: $white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            .category-text {
              font-size: 12px;
              margin: unset;
              text-align: center;
            }
          }
        }
      }
    }
    .image-container {
      width: 60px;
      height: 60px;
      .upload-container {
        padding: 0;
        height: 100%;
        label {
          width: 100%;
          height: 100%;
        }
        .upload-image-demo {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .select-box {
    margin-bottom: 14px;
  }
  .page-heading {
    font-weight: bold;
    margin-bottom: 72px;
  }
  .content-grid {
    margin-top: 36px;
  }
  .content-header {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
  }
  .help-icon {
    margin: 0 10px;
    &.upload {
      margin: 0 5px;
      filter: $filter-dark-gray;
    }
  }
  .content-grid {
    .grid-row {
      margin-bottom: 24px;
    }
    .background-color {
      &-box {
        width: 50px;
        height: 50px;
        border: 1px solid $dark-gray;
        border-radius: 3px;
      }
    }
    .phone-container {
      position: relative;
      width: 305px;
      &-header {
        width: 150px;
        text-align: center;
        position: absolute;
        top: 50px;
        left: 80px;
        color: $white;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &-top-nav {
        position: absolute;
        top: 19px;
        left: 23px;
      }
      &-bottom-nav {
        position: absolute;
        bottom: 27px;
        left: 100px;
      }
      &-logo {
        position: absolute;
        bottom: 31px;
        left: 142px;
        width: 28px;
        height: 28px;
      }
    }
    .column-headers {
      border-bottom: 1px solid $dark-gray;
      padding-bottom: 18px;
      margin-bottom: 22px;
      .column-name {
        color: $dark-gray;
        font-size: 12px;
      }
    }
    .select-all {
      margin-bottom: 22px;
      &-checkbox {
        padding: 0;
      }
      &-text {
        margin-left: 9px;
        margin-right: 9px;
      }
      .double-icon {
        margin: 0 8px;
        & > * {
          margin: 0 12px;
        }
      }
    }
    .item-box {
      border: 1px solid $dark-gray;
      border-radius: 5px;
      padding: 20px 0;
      margin-bottom: 22px;
      &:active,
      &:hover {
        border-color: $light-blue;
      }
    }
    .MuiTextField-root {
      margin-bottom: 15px;
    }
    .item-margin {
      margin: 0 3.2%;
    }
    .add-item-box {
      display: flex;
      align-items: center;
      font-size: 15px;
    }
  }
  .categories-page {
    .select-filter {
      margin-bottom: 15px;
    }
    .benefits-preview-container {
      position: relative;
      .benefit-phone {
        width: 241px;
        height: 487px;
        @media only screen and (max-width: 768px) {
          width: 180.75px;
          height: 365.25px;
        }
      }
      .benefits-preview {
        position: absolute;
        display: flex;
        justify-content: center;
        top: 58px;
        left: 21px;
        width: 205px;
        height: 379px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        .category-container {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          width: 92%;
          margin-top: 13px;
        }
        .category {
          position: relative;
          width: 86px;
          height: 86px;
          margin: 3px;
          img,
          .no-image {
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
          .no-image {
            background-color: $black;
          }
          div {
            color: $white;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            span {
              font-size: 12px;
            }
          }
        }
        @media only screen and (max-width: 768px) {
          width: 153.75px;
          height: 284.25px;
          top: 44px;
          left: 13px;
          .category {
            width: 64.5px;
            height: 64.5px;
            div {
              .category-text {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
    .image-container {
      width: 60px;
      height: 60px;
      @media only screen and (max-width: 1024px) {
        height: 69px;
        width: 69px;
        &.preview {
          img {
            height: 69px;
            width: 69px;
          }
        }
      }
      .upload-container {
        padding: 0;
        height: 100%;
        label {
          width: 100%;
          height: 100%;
        }
        .upload-image-demo {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .select-box {
    margin-bottom: 14px;
  }
}
