@import "../../../assets/scss/variables";

.card-component-wrapper {
    background: $white;
    position: relative;
    height: 298px;
    width: 100%;
    border: 1px solid #e6eaee;
    box-sizing: border-box;
    transition: box-shadow 250ms linear;
    padding-top: 25px;
    @media only screen and (max-width : 1024px) {
        height: 200px;
    }
    @media only screen and (max-width : 768px) {
        height: 150px;
    }
    @media only screen and (max-width : 736px) {
        padding-top: 0;
    }
    .card-item-content-wrapper{
        position: absolute;
        opacity: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding-top: 15px;
    }
    .icon{
        padding-top: 29px;
        width: 32%;
        @media only screen and (max-width : 1024px) {
            padding-top: unset
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    &:nth-of-type(1) {
        margin-left: 0px;
    }
    margin-left: 24px;
    justify-content: center;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.129835);
        .card-content {
            color: $light-blue;
        }
    }
    .card-content {
        margin-top: 29px;
        transition: all .2s ease-in-out;
        @media only screen and (max-width : 736px) {
            margin-top: 15px;
        }
    }
    .hide {
        transition: opacity 500ms ease-in-out;
        opacity: 0;
    }
    .fade-in {
        transition: opacity 500ms ease-in-out;
        opacity: 1;
    }
    .show {
        transition: all 0.2s ease-in-out;
        opacity: 1;
    }
    .card-item-loader-wrapper {
        width: 100%;
        height: 100%;
        cursor: default;
        position: absolute;
        z-index: 4;
    }
}
