@import "../assets/scss/variables";

.sign-form-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
        font-weight: normal;
        margin: 0;
    }
    .separator {
        height: 50px;
        width: 100%;
    }
    .sign-form {
        .sign-container {
            .sign-header {
                .double-column {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    .select-language {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 71px;
                        height: 44px;
                        background: $white;
                        border: 1px solid $border-lightGray;
                        div {
                            margin: 0 10px;
                            width: 90%;
                            &:focus {
                                background-color: unset;
                            }
                            &::before,
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
            .sign-body {
                margin-top: 80px;
                padding: 70px 70px 30px;
                background: $white;
                border: 1px solid $border-lightGray;
                @media only screen and (max-width : 767px) {
                    padding: 70px 35px 30px;
                }
                .sign-image-container {
                    position: relative;
                    .sign-image-and-icon {
                        position: absolute;
                        width: 125px;
                        height: 125px;
                        bottom: 10px;
                    }
                    .sign-image {
                        position: absolute;
                        width: 125px;
                        height: 125px;
                        border-radius: 50%;
                        bottom: 0;
                        @media only screen and (max-width : 767px) {
                            width: 100px;
                            height: 100px;
                            left: 13px;
                        }
                        .reactEasyCrop_Container {
                            border-radius: 50%;
                        }
                        .reactEasyCrop_Image {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .add-image-btn {
                        position: absolute;
                        background: $light-blue;
                        border-radius: 50%;
                        bottom: 0;
                        right: 5px;
                        width: 24px;
                        height: 24px;
                        .plus-icon {
                            font-size: 20px;
                            filter: $filter-white;
                        }
                    }
                }
                .sign-field {
                    padding: 10px 0 0;
                }
                .sign-buttons {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 25px;
                    &.align-start {
                        align-items: flex-start;
                    }
                    .link {
                        margin-top: 28px;
                    }
                }
                .visibility-icon {
                    margin-bottom: 10px;
                }
                .sent-message {
                    text-align: center;
                }
            }
            .second-container {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 24px 0;
                background: $white;
                border: 1px solid $border-lightGray;
                button {
                    font-size: 16px;
                }
            }
        }

        input[type="number"]::-webkit-clear-button {
            display: none;
        }

        //remove inside of arrows button
        input[type="number"]::-webkit-inner-spin-button {
            display: none;
        }

        //remove outsideof arrows button
        input[type="number"]::-webkit-outside-spin-button {
            display: none;
        }
    }

    .border-separator {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px 0;
        .border-line {
            width: 49%;
            border-bottom: 1px solid $border-gray;
        }
        .border-text {
            font-size: 18px;
            padding: 0 15px;
        }
    }
}

.inner-full-container {
    height: 100vh;
    overflow-y: auto;
    padding: 20px 0;
    .center-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.terms-and-policy {
    padding: 40px 0;
    text-align: center;
    a {
        color: $black;
        text-decoration: underline;
    }
}

.main-content-container {
    position: relative;
    overflow: hidden;
    @media only screen and (max-width : 736px) {
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

@media only screen and (max-width : 1023px) {
    .sign-form-container {
        min-height: unset;
        .inner-full-container {
            height: unset;
            overflow-y: unset;
        }
    }
}