.table-grid.dashboard-articles-search {
    // .actions-column {
    //     margin-left: 30px;
    // }
    .table-row-actions {
        width: 80%;
        display: flex;
        justify-content: space-between;
    }
}
