@import "../../../../assets/scss/variables";

.mobile-footer {
    width: 100%;
    height: 56px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    border-top: 1px solid $border-gray;
    .grid-container {
        background: $white;
        z-index: 3;
        position: relative;
        height: 100%;
        width: 100%;
        .action-icon {
            font-size: 24px;
            filter: $filter-light-blue;
        }
        .plus-icon-wrapper {
            position: absolute;
            bottom: 20px;
            width: 52px;
            height: 52px;
            transition: transform 0.5s ease-in-out;
            img {
                width: 100%;
                height: auto;
            }
            &.x-state {
                transform: rotate(45deg)
            }
        }
        .profile-text {
            font-size: 12px;
            color: $light-blue;
        }
    };
}
