$dark-blue: #131340;
$medium-blue: #00208d;
$light-blue: #577bf9;
$cyan: #6BD7FD;
$yellow: #EFDA1F;

$dark-gray: #8f9bb3;
$medium-gray: #edf0ff;
$light-gray: #edf1f7;
$grey: #D3D3D3;

$black: #000000;
$white: #fff;

$border-lightGray: #E6EAEE;
$border-gray: #D8D8D8;

$dashboard-background: #f6f7f8;

$message-header: #ECECEC;

$error-red: #f44336;

$community-font: Arial;

$ProximaNova-Regular: ProximaNova-Regular;
$ProximaNova-Bold: ProximaNova-Bold;
$ProximaNova-Thin: ProximaNova-Thin;

$filter-light-blue: invert(40%) sepia(100%) saturate(615%) hue-rotate(197deg) brightness(98%) contrast(98%); // taken from $light-blue: #577bf9;
$filter-dark-gray: invert(65%) sepia(11%) saturate(587%) hue-rotate(182deg) brightness(93%) contrast(86%); // taken from $dark-gray: #8f9bb3;
$filter-white: invert(100%) sepia(0%) saturate(7494%) hue-rotate(230deg) brightness(100%) contrast(102%); // #fff
$filter-black: invert(0%) sepia(46%) saturate(4647%) hue-rotate(25deg) brightness(90%) contrast(105%); // #000
$filter-red: invert(32%) sepia(98%) saturate(3604%) hue-rotate(341deg) brightness(109%) contrast(125%); // #ff262b - mobile heart color
$filter-cyan: invert(67%) sepia(73%) saturate(377%) hue-rotate(164deg) brightness(102%) contrast(98%); // $cyan: #6BD7FD;
