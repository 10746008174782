.main-content.account-page {
  .page-heading {
    font-weight: bold;
  }

  .card-wrapper {
    margin-bottom: 40px;
  }

  .image-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 736px) {
      align-items: center;
      margin-top: 30px;
    }
    .avatar {
      width: 100%;
      height: 100%;
      margin-bottom: 45px;
    }
    .image-wrap {
      text-align: center;
      position: relative;
      height: 250px;
      width: 250px;
      @media only screen and (max-width: 768px) {
        width: 187.5px;
        height: 187.5px;
      }
    }
  }
  .info-section {
    margin-top: 30px;
  }

  ol {
    padding-left: 30px;
  }

  .info-section-title {
    font-weight: bold;
  }

  .crop-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .proportion-container {
    margin-top: 15px;
    font-weight: bold;
    font-size: 13px;
    width: inherit;
    text-align: center;
    @media only screen and (max-width: 1024px) {
      font-size: 10px;
    }
  }
  .upload-wrapper {
    width: inherit;
    text-align: center;
  }
}

.community-rtl {
  .main-content.account-page {
    @media only screen and (max-width: 736px) {
      margin-right: 11.3%;
    }
  }
}
