@import "../../assets/scss/variables";

.modal-preview {
    .preview-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 600px;
        height: 815px;
        background: $white;
        margin: 68px auto;
        @media only screen and (max-width: 1440px) {
            width: 375px;
            height: 509px;
        }
        @media only screen and (max-width: 768px) {
            width: 300px;
            height: 407.5px;
        }
        .phone-container {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: fit-content;
            height: 65%;
            .phone-image {
                width: auto;
                height: 100%;
            }
            .content-container {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 6.6%;
                width: 85.5%;
                height: 87%;
                border-radius: 21px;
            }
        }
        .preview-buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            button {
                font-size: 1rem;
                margin: 20px auto;
            }
        }

        .preview-close {
            position: absolute;
            top: 20px;
            right: 20px;
            .icon-close {
                cursor: pointer;
                font-size: 1.5rem;
            }
        }
    }
}
