.community-range-picker {
    .from-date {
        position: absolute;
        top: -70px;
        right: 462px;
        width: 0;
        opacity: 0;
        cursor: none;
        .MuiButtonBase-root {
            cursor: default;
        }
    }
    .to-date {
        position: absolute;
        top: -70px;
        right: 150px;
        width: 0px;
        opacity: 0;
        cursor: none;
        .MuiButtonBase-root {
            cursor: default;
        }
    }
    // .date-picker-from-date, .date-picker-to-date {
    //     display: none;
    // }
}

.range-date-picker {
    .MuiPopover-root:nth-of-type(2), .MuiPopover-root:nth-of-type(3) {
        position: absolute !important;
        width: 345px;
        height: 337px;
        div[aria-hidden="true"] {
            display: none;
        }
    }
    
    &.community-rtl {
        .MuiPopover-root {
            &:nth-of-type(2) {
                margin: 318px 50% !important;
                .MuiPaper-root.MuiPopover-paper {
                    top: 0 !important;
                    left: 0 !important;
                }
            }
            &:nth-of-type(3) {
                margin: 318px 73% !important;
                .MuiPaper-root.MuiPopover-paper {
                    top: 0 !important;
                    left: 0 !important;
                }
            }
    
        }
    }

}
