html {
  scroll-behavior: smooth;
  scrollbar-color: #577bf9 #d3d3d3;
  scrollbar-width: thin;
}
body {
  scroll-behavior: smooth;
  margin: 0;
  font-family: "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  background-color: #f6f7f8;
  scrollbar-color: #577bf9 #d3d3d3;
  scrollbar-width: thin;
  overflow: hidden;
  min-width: 320px;
  /* max-width: 1920px; */
  overflow-x: auto;
}

@media only screen and (max-width : 1440px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width : 1024px) {
  html {
    font-size: 11px;
  }
}

@media only screen and (max-width : 768px) {
  html {
    font-size: 8px;
  }
}

@media only screen and (max-width : 480px) {
  html {
    font-size: unset;
  }
}

code {
  font-family: "Arial";
}