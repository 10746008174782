@import "../../../../assets/scss/variables";

.activity-item-container {
    height: 100%;
    padding-left: 3px;
    position: relative;
    cursor: pointer;
    transition: all ease 0.2s;
    z-index: 444;
    &:hover {
        .activity-item-content-wrapper {
        .profile-img-wrapper {
            transition: all ease 0.2s;
            transform: scale(1.1);
            img {
                
            }
        }
    }
    }
    &:nth-of-type(1){
        padding-top: 5px;
    }
    
    .activity-item-content-wrapper {
        width: 100%;
        opacity: 0;
        display: flex;
        align-items: center;
        position: absolute;
        z-index: 5;
    }

    img {
        height: 50px;
        width: 50px;
        border-radius: 100%;
    }
    .user-without-img {
        font-size: 15px;
        padding: 16px;
        border-radius: 100%;
        background: $dark-gray;
        color: $white;
        @media only screen and (max-width : 768px) {
            font-size: 12px;
        }
    }

    .activity-text-wrapper {
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 6px;
        width: 330px;
        margin-left: 30px;
        @media only screen and (max-width : 768px) {
            margin-left: 15px;
        }
        .activity-text {
            width: 100%;
            @media only screen and (max-width : 768px) {
                font-size: 12px;
            }
        }
    }
    .latest-activity-loader-wrapper {
        cursor: pointer;
        position: absolute;
        z-index: 2;
    }
    .hide {
        transition: opacity 500ms ease-in-out;
        opacity: 0;
    }
    .fade-in {
        transition: opacity 500ms ease-in-out;
        opacity: 1;
    }
    .show {
        transition: opacity 500ms ease-in-out;
        opacity: 1;
    }
}
