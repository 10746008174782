@import "../../assets/scss/variables";

.table-wrapper {
  border: 1px solid $border-gray;
  border-radius: 5px;
  position: relative;
  font-size: 0.875rem;

  .div-head-scroll {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .div-head-wrapper {
      width: 100%;
      padding: 0;
    }
  }
  .drag-and-drop-head {
    display: none;
  }
  thead > tr,
  .div-head-scroll {
    display: flex;
    align-items: center;
    width: inherit;
    border-bottom: 1px solid $border-gray;
    height: 56px;
    & > th,
    .div-th {
      &.sort {
        cursor: pointer;
        display: flex;
        align-items: center;
        .sort-icon {
          opacity: 0;
          width: 18px;
          height: 18px;
          color: $border-gray;
          padding: 4px 4px 0;
        }
        &.active {
          .sort-icon {
            opacity: 1;
            color: rgba(0, 0, 0, 0.54);
          }
        }
        &:hover {
          .sort-icon {
            opacity: 1;
          }
        }
      }
    }
    .div-th {
      box-sizing: border-box;
      padding: 0 10px;
    }
  }
  .fixed-header {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    & > thead {
      color: black;
    }

    & > tbody {
      display: block;
      width: inherit;
      overflow: auto;
      cursor: grabbing;
      .tr-body-wrapper {
        height: 90px;
        cursor: default;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $border-gray;
        td {
          box-sizing: border-box;
          padding: 0 10px;
        }
        &:last-child {
          border: 0;
        }
        .handle-container {
          position: absolute;
          left: -50px;
          .firstElement {
            display: flex;
            flex-direction: row;
            .handle {
              margin-right: 10px;
              padding: 0 5px;
              cursor: grab;
              box-sizing: border-box;
            }
          }
        }
      }
    }

  }
  & > thead th,
  & > .div-head-scroll,
  & > tbody td {
    padding: 0 10px;
    text-align: right;
    box-sizing: border-box;
    &.div-head-scroll {
      padding: 0;
    }
    &.text-center {
      text-align: center;
    }
  }
  .table-row-title-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .flex-item {
    flex: 1;
  }
}

.helperContainerClass {
  position: relative;
  display: flex;
  align-items: center;
  width: inherit;
  border: 1px solid #efefef;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  width: 100%;
  &:active {
    cursor: grabbing;
  }

  & > td {
    padding: 5px;
    text-align: left;
    box-sizing: border-box;
  }

  .handle-container {
    position: absolute;
    left: -50px;
    .firstElement {
      display: flex;
      flex-direction: row;
      .handle {
        margin-right: 10px;
        padding: 0 5px;
        cursor: grab;
        box-sizing: border-box;
      }
    }
  }
}

.community-rtl {
  .tr-body-wrapper {
    .handle-container {
      left: unset !important;
      right: -65px !important;
    }
  }
}
