@import '../../../assets/scss/variables';

.main-content {
    .community-page {
        .can-remove-th {
            text-align: right;
        }
        .box-wrapper {
            position: relative;
            margin: 100px 0 50px;
            padding: 20px;
            background-color: $white;
            .wrapper-image {
                margin-bottom: 75px;
                .circle-container {
                    position: absolute;
                    left: 36%;
                    top: -19%;
                    width: 126px;
                    height: 126px;
                    border-radius: 50%;
                    @media only screen and (max-width : 1024px) {
                        width: 90px;
                        height: 90px;
                        left: 29%;
                        top: -14%;
                    }
                    img {
                        width: auto;
                        height: 100%;
                    }
                    &.logo {
                        background-color: $light-gray;                        
                    }
                }
            }
            .wrapper-header {
                font-size: 18px;
                margin-bottom: 50px;
            }
            .grid-row {
                & > * {
                    margin: 20px 0;
                }
                .email {
                    height: 45px;
                    @media only screen and (max-width : 768px) {
                        height: 33px;
                    }
                }
            }
            .select-form-control {
                @media only screen and (max-width : 1024px) {
                    margin: 14px 0;
                }
                .select-group {
                    width: 200px;
                    margin: 0;
                    @media only screen and (max-width : 1024px) {
                        width: 130px;
                    }
                }
            }
        }
        .table-grid-header {
            margin-bottom: 20px;
        }
        .table-row-check {
            display: flex;
            align-items: center;
            .table-row-checkIcon {
                display: none;
            }
            &.checked {
                .table-row-checkIcon {
                    display: unset;
                }
            }
        }
        .table-grid .table-actions {
            justify-content: space-around;
        }
        @media only screen and (max-width : 768px) {
            .box-wrapper-container {
                border: 10px solid transparent;
                box-sizing: border-box;
            }
        }
    }
    &.view-community {
        .submit-button {
            button {
                margin-bottom: 50px;
            }
        }
    }
}

.modal-editors {
    .editors-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        position: relative;
        width: 600px;
        height: 77vh;
        background: $white;
        margin: 68px auto;
        padding-top: 30px;
        .user-container {
            width: 80%;
            .user-search {
                width: 50%;
            }
            .user-list {
                height: 380px;
                overflow-y: auto;
                .search-field-container {
                    margin: 0 15px;
                    width: 58%;
                    svg {
                        width: 24px;
                        filter: saturate(0%) brightness(0.5);
                    }
                }
                .select-checkbox {
                    padding: 0;
                }
            }
        }
    }
    .editors-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        border-top: 1px solid $border-gray;
        button {
            font-size: 1rem;
            margin: 20px auto;
        }
    }

    .editors-close {
        display: flex;
        justify-content: space-between;
        width: 80%;
        .editors-header {
            font-weight: 600;
        }
        .icon-close {
            cursor: pointer;
            font-size: 1.5rem;
            width: 20px;
        }
    }
}