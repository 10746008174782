@import "../../../assets/scss/variables";

.clicks-modal-page-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 35%;
    height: 100%;
    z-index: -1;
    &.open {
        left: 0;
        z-index: 10;
    }
    .clicks-modal-page {
        position: absolute;
        width: 100%;
        height: 100%;
        left: -100%;
        background: $white;
        border-left: 1px solid $border-gray;
        border-right: 1px solid $border-gray;
        transition: all 0.5s ease-out;
        &.open {
            left: 0;
        }
        .click-modal-header {
            height: 60px;
            border-bottom: 1px solid $border-gray;
            padding: 0 24px;
            h3 {
                color: rgba($black,0.87);
            }
            .export-button {
                cursor: pointer;
                margin: 0 15px;
                &-text {
                    margin: 0 5px;
                    color: $light-blue;
                }
                .icon {
                    img {
                        width: 1.42rem;
                        height: 1.42rem;
                    }
                }
            }
            .close-container {
                top: 20px;
                left: 20px;
                .icon-close {
                    cursor: pointer;
                    font-size: 1.5rem;
                }
            }
        }
        .click-modal-footer {
            padding: 0 24px;
            .content-wrap {
                margin-top: 45px;
                overflow-y: auto;
            }
            .MuiDataGrid-windowContainer {
                .MuiDataGrid-window {
                    top: 80px !important;
                }
            }
        }
        .total-count {
            color: $dark-gray;
        }
    }
}