.latest-activity-list-container {
    padding: 4.7%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    .activity-list-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        scrollbar-width: thin;
    }

    .latest-activity-title {
        font-size: 18px;
        margin-bottom: 30px;
    }
}
