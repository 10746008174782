@import "../../../assets/scss/variables";
.btn-with-icon-wrapper{
    cursor: pointer;
    background-color: $light-blue;
    border: none;
    border-radius: 3px;
    height: 31px;
    width: 37px;
    &:active{
        outline: none;
    }
    &:focus{
        outline: none;
    }
    img{
        height: 100%;
        width: 100%;
    }
}
